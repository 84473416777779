import {combineReducers} from 'redux';
import listDux from './lib/listDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';
import detailsDux from './lib/detailsDux';

import {setSnackbar} from '../react-components/snackbar';

const apiPrefix = '/api/enterprises/v2';

const {reducer: $listPlans, actions: listPlans, epics: listPlansEpic} = listDux({
  name: 'enterprises.plans',
  url: `${apiPrefix}/plans`,
});

const {reducer: $subscribe, actions: subscription, epics: subscribeEpic} = createDux({
  name: 'enterprises.subscription',
  url: `${apiPrefix}/subscriptions/`,
  processResponse: ([{response: {success, error, data}}]) => {
    setSnackbar({message: success ? 'subscribed': (error.message || 'failed to subscribe')});
    return ({subscriptionResponse: {success, error, data}});
  }
});


const {reducer: $subscriptions, actions: subscriptions, epics: subscriptionsEpic} = listDux({
  name: 'enterprises.subscriptions',
  url: `${apiPrefix}/subscriptions/`,
});

const {reducer: $getSubscription, actions: getSubscription, epics: getSubscriptionEpic} = detailsDux({
  name: 'enterprises.subscriptions',
  url: `${apiPrefix}/subscriptions/`,
});

const {
  reducer: $subscriptionTrackers,
  actions: subscriptionTrackers,
  epics: subscriptionTrackersEpic
} = listDux({
  name: 'enterprises.subscription.trackers',
  url: `${apiPrefix}/subscriptions/trackers`,
});

const {reducer: $updateSubscription, actions: updateSubscription, epics: updateSubscriptionEpic} = updateDux({
  name: 'enterprises.subscription',
  url: `${apiPrefix}/subscriptions/`,
  processResponse: ([{response: {success, error, data}}]) => {
    setSnackbar({message: success ? 'subscription updated': (error.message || 'subscription update failed.')});
    return ({subscriptionResponse: {success, error, data}});
  }
});

const {
  reducer: $createSubscriptionInvoice,
  actions: createSubscriptionInvoice,
  epics: createSubscriptionInvoiceEpic,
} = createDux({
  name: 'enterprises.subscription-invoices',
  url: `${apiPrefix}/invoices`,
  createPayload: (data={}) => {
    const diggableObject = (obj) => [Blob, Date,].every(C => !(obj instanceof C));
    const createFormData = ({data, keyPrefix='data', formData}) => {
      Object.entries(data).map(([key, val]) => {
        if (!val)
          return;

        if (typeof val == 'object' && diggableObject(val)) {
          createFormData({data: val, keyPrefix: `${keyPrefix}[${key}]`, formData});
        } else {
          formData.set(`${keyPrefix}[${key}]`, val);
        }
      });

      return formData;
    };

    const formData = new FormData();
    createFormData({data, formData});
    return {data: formData, isCreating: true};
  },
  getRequest(url, {headers, ...request}, [{payload: {data, options}}, {auth: {authToken}={}}={}]) {
    return {
      ...request,
      url,
      headers: {
        ...(authToken ? {Authorization: `Bearer ${authToken}`} : {}),
      },
      method: 'POST',
      body: data,
    };
  },
  processResponse: ([{response: {success, error, data}}]) => {
    setSnackbar({
      message: (
        success
        ? 'Invoice Uploaded'
        : (error.message || 'Invoice upload failed')
      ),
    });
    return ({response: {success, error, data}});
  },
});

const {
  reducer: $updateSubscriptionInvoice,
  actions: updateSubscriptionInvoice,
  epics: updateSubscriptionInvoiceEpic,
} = updateDux({
  name: 'enterprises.subscription-invoices',
  url: `${apiPrefix}/invoices`,
  processResponse: ([{response: {success, error, data}}]) => {
    setSnackbar({
      message: (
        success
        ? 'Invoice Updated'
        : (error.message || 'failed to update invoice')
      ),
    });
    return ({
      error,
      errorMessage: (error && error.message) || (!success && 'server error'),
      data,
    });
  }
});

const {
  reducer: $getSubscriptionInvoice,
  actions: getSubscriptionInvoice,
  epics: getSubscriptionInvoiceEpic,
} = detailsDux({
  name: 'enterprises.subscription-invoices',
  url: `${apiPrefix}/invoices`,
});

const {
  reducer: $listSubscriptionInvoices,
  actions: listSubscriptionInvoices,
  epics: listSubscriptionInvoicesEpic,
} = listDux({
  name: 'enterprises.subscription-invoices',
  url: `${apiPrefix}/invoices`,
});

export const actions = {
  listPlans,
  subscription,
  subscriptions,
  getSubscription,
  updateSubscription,
  subscriptionTrackers,
  getSubscriptionInvoice,
  listSubscriptionInvoices,
  createSubscriptionInvoice,
  updateSubscriptionInvoice,
};
export const epics = [
  ...listPlansEpic,
  ...subscribeEpic,
  ...subscriptionsEpic,
  ...getSubscriptionEpic,
  ...updateSubscriptionEpic,
  ...subscriptionTrackersEpic,
  ...getSubscriptionInvoiceEpic,
  ...listSubscriptionInvoicesEpic,
  ...createSubscriptionInvoiceEpic,
  ...updateSubscriptionInvoiceEpic,
];
export default combineReducers({
  $listPlans,
  $subscribe,
  $subscriptions,
  $getSubscription,
  $updateSubscription,
  $subscriptionTrackers,
  $getSubscriptionInvoice,
  $listSubscriptionInvoices,
  $createSubscriptionInvoice,
  $updateSubscriptionInvoice,
});
