import { registerApplication, start } from 'single-spa';
import '../../utils/auth';
import '../../index.css';

import routes from './routes';
import getRoutingApps from '../getRoutingApps';
import getNonRoutingApps from '../getNonRoutingApps';
import {persistCurrentAuthToken} from '../utils';

document.addEventListener('mousedown', persistCurrentAuthToken);

const apps = {
  ...getRoutingApps(routes), 
  ...getNonRoutingApps(routes.filter(({customProps: {instance='icici'}={}}) => instance.includes('icici') || instance.includes('all'))), 
};
Object.entries(apps).map(([name, {app: appPath=name, activeWhen=() => false, customProps={}}]) => {
  const app = () => import(`../../${appPath}/app.js`).then(({app}) => app);
  registerApplication({name, app, activeWhen, customProps:{...customProps, instance:'icici'}});
});

start();