import { combineReducers } from 'redux';

import listDux from './lib/listDux';


const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'harmonizedSystemCodes',
  url: '/api/bookings/hs-codes',
});

export const actions = { list };
export const epics = [...listEpics,];
export default combineReducers({ $list });