import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';

const { reducer: $products, actions: products, epics: productsEpics } = culdDux({
  name: 'products',
  url: '/api/product-catalog/products',
});

const { reducer: $bulkProducts, actions: bulkProducts, epics: bulkProductsEpics } = culdDux({
  name: 'bulkProducts',
  url: '/api/product-catalog/bulkProducts',
});

const { reducer: $bulkProductLogs, actions: bulkProductLogs, epics: bulkProductLogsEpics } = culdDux({
  name: 'bulkProductLogs',
  url: '/api/product-catalog/bulkProductLogs',
});

export const actions = { products, bulkProducts, bulkProductLogs, };
export const epics = [...productsEpics, ...bulkProductsEpics, ...bulkProductLogsEpics,];
export default combineReducers({ $products, $bulkProducts, $bulkProductLogs });