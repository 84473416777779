import React from 'react';

import Icon from './Icon';
import * as Styles from './Styles.css';

function ActionIcon({isDisabled=false, isSelected=false, negativeVerticalMargin=true, shiftHorizontal=true, shiftLeft=shiftHorizontal, shiftRight=shiftHorizontal, compact=false, className='', ...props}, ref) {
  return (
    <Icon className={`${Styles.action} ${compact ? Styles.compact : ''} ${negativeVerticalMargin ? Styles.negativeVerticalMargin : ''} ${isDisabled ? Styles.disabled : ''} ${isSelected ? Styles.selected : ''} ${shiftLeft ? Styles.shiftLeft : ''} ${shiftRight ? Styles.shiftRight : ''} ${className}`} ref={ref} disabled={isDisabled} {...props}/>
  )
}

export default React.forwardRef(ActionIcon);