import { combineReducers } from 'redux';

import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'rfqTerms',
  url: '/api/rfq/terms/',
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'rfqTerms',
  url: '/api/rfq/terms/',
  setDetailsFromListPayload: () => { },
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'rfqTerms',
  url: '/api/rfq/terms/',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'rfqTerms',
  url: '/api/rfq/terms/',
  switchRequests: false,
});

export const actions = { list, details, create, update };
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics];
export default combineReducers({ $list, $details, $create, $update });