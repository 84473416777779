// extracted by mini-css-extract-plugin
export var width5 = "width5___nVJFm";
export var width10 = "width10___aPcPY";
export var width15 = "width15___q3YrY";
export var width20 = "width20___akzgc";
export var width25 = "width25___yjFas";
export var width30 = "width30___sLfaM";
export var width35 = "width35___hGyQt";
export var width40 = "width40___oup4C";
export var width45 = "width45___VrtfF";
export var width50 = "width50___r3C8A";
export var width55 = "width55___hRBWX";
export var width60 = "width60___crEl_";
export var width65 = "width65___yuqYS";
export var width70 = "width70___r8oES";
export var width75 = "width75___uAvL1";
export var width80 = "width80___yes8a";
export var width85 = "width85___o5Hg5";
export var width90 = "width90___qKRk_";
export var width95 = "width95___Pg36U";
export var width100 = "width100___C9plQ";
export var layoutRow = "layout-row___VJYGb";
export var layoutRowReverse = "layout-row-reverse___RgzKO";
export var layoutColumn = "layout-column___eYu0U";
export var layoutColumnReverse = "layout-column-reverse___znCVg";
export var layoutJustifyStart = "layout-justify-start___ae1cm";
export var layoutJustifyFlexStart = "layout-justify-flex-start___eDX2b";
export var layoutJustifyCenter = "layout-justify-center___kl8_9";
export var layoutJustifyEnd = "layout-justify-end___eh66V";
export var layoutJustifyFlexEnd = "layout-justify-flex-end___yk80J";
export var layoutJustifySpaceAround = "layout-justify-space-around___JJaA6";
export var layoutJustifySpaceBetween = "layout-justify-space-between___CYQZj";
export var layoutJustifySpaceEvenly = "layout-justify-space-evenly___CugGI";
export var layoutAlignStart = "layout-align-start___sdoee";
export var layoutAlignFlexStart = "layout-align-flex-start___NfQgB";
export var layoutAlignCenter = "layout-align-center___JuJ0f";
export var layoutAlignEnd = "layout-align-end___wKZXZ";
export var layoutAlignFlexEnd = "layout-align-flex-end___NrRaa";
export var layoutAlignStretch = "layout-align-stretch___B8Noc";
export var flexOrder0 = "flex-order-0___rFh2y";
export var flexOrder1 = "flex-order-1___RznB0";
export var flexOrder2 = "flex-order-2___gI4hH";
export var flexOrder3 = "flex-order-3___bfE5_";
export var flexOrder4 = "flex-order-4___yIj0F";
export var flexOrder5 = "flex-order-5___m5jXm";
export var flexOrder6 = "flex-order-6___RVZa0";
export var flexOrder7 = "flex-order-7___sTToj";
export var flexOrder8 = "flex-order-8___PD9Ao";