import {fromEvent, of, from, empty} from 'rxjs';
import {first, take, map, filter, mergeMap, switchMap, mapTo, withLatestFrom, catchError} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {ofType} from 'redux-observable';

import {setSnackbar} from '../react-components/snackbar';

export const actions = {
  getAuthToken: 'auth.getAuthToken',
  setAuthToken: 'auth.setAuthToken',
  getUsers: 'auth.getUsers',
  setUser: 'auth.setUser',
  setUserCompany: 'auth.setUserCompany',
  setEnterprisePlan: 'auth.setEnterprisePlan',
  login: 'auth.login',
  loginFailed: 'auth.login.failed',
  loginSuccess: 'auth.login.success',
  signUp: 'auth.signUp',
  signUpSuccess: 'auth.signUp.success',
  signUpFailed: 'auth.signUp.failed',
  logout: 'auth.logout',
  forgotPassword: 'auth.forgotPassword',
  generateResetPasswordToken: 'auth.generateResetPasswordToken',
  getResetPasswordToken: 'auth.getResetPasswordToken',
  setResetPasswordToken: 'auth.setResetPasswordToken',
  setPassword: 'auth.setPassword',
  updatePassword: 'auth.updatePassword',
  setSideNav: 'auth.setSideNav',
  getSideNav: 'auth.getSideNav',
}

export default function reducer(state = {
  isAuthenticating: true,
  isLoggedIn: false,
  showSideNav:false,
  user: undefined,
  userCompany: {
    data: undefined,
    error: undefined,
  },
  enterprisePlan: {
    data: undefined,
    error: undefined,
  },
  users: [],
  authToken: undefined,
  login: {},
  resetPasswordToken: {},
  setPassword: {},
}, {type, payload={}}) {
  switch(type) {
    case actions.getAuthToken:
      return {
        ...state,
        ...payload,
      }
    case actions.getUsers:
      return {
        ...state,
        ...payload,
      }
    case actions.setUser:
      return {
        ...state,
        ...payload,
      }
    case actions.setUserCompany:
      return {
        ...state,
        userCompany: {
          ...state.userCompany,
          ...payload
        }
      }
    case actions.setEnterprisePlan:
      return {
        ...state,
        enterprisePlan: {
          ...state.enterprisePlan,
          ...payload,
        },
      }
    case actions.login:
      return {
        ...state,
        login: {
          ...state.login,
          isLoggingIn: true,
        }
      }
    case actions.loginFailed:
      return {
        ...state,
        login: {
          isLoggingIn: false,
          failed: true,
          error: payload.error,
        }
      }
    case actions.signUp:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          isSigningUp: true,
        }
      }
    case actions.signUpSuccess:
      return {
        ...state,
        signUp: {
          isSigningUp: false,
          success: true,
          failed: false,
          error: null,
        }
      }
    case actions.signUpFailed:
      return {
        ...state,
        signUp: {
          isSigningUp: false,
          failed: true,
          success: false,
          error: payload.error,
        }
      }
    case actions.forgotPassword:
      return {
        ...state,
        resetPasswordToken: {
          ...payload,
          isFetching: true,
          _id: undefined,
          token: undefined,
        }
      }
    case actions.generateResetPasswordToken:
      return {
        ...state,
        resetPasswordToken: {
          isFetching: true,
          _id: undefined,
          token: undefined,
        },
      }
    case actions.getResetPasswordToken:
      return {
        ...state,
        resetPasswordToken: {
          ...payload,
          isFetching: true,
          token: undefined,
        },
      }
    case actions.setResetPasswordToken:
      return {
        ...state,
        resetPasswordToken: {
          ...payload,
          isFetching: false,
        },
      }
    case actions.setPassword:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          isSaving: false,
          ...payload,
        }
      }
    case actions.updatePassword:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          isSaving: true,
          ...payload,
        }
      }
    case actions.setSideNav:
      return {
        ...state,
        ...payload
      }
    case actions.getSideNav:
      return {
        ...state,
         
      }         
    default:
      return state;
  }
}

export const setSideNav = (value) => ({
  type: actions.setSideNav,
  payload: {
    showSideNav: value,
  }
});

export const getSideNav = () => ({
  type: actions.getSideNav,
  payload: {
    showSideNav: false,
  }
});

export const getAuthToken = (authToken=sessionStorage.getItem('authToken'), users=localStorage.getItem('auth.users'), persistedAuthToken=localStorage.getItem('auth.persistedAuthToken')) => ({
  type: actions.getAuthToken,
  payload: {
    authToken: authToken || (persistedAuthToken && users && JSON.parse(users).find(({authToken}={}) => authToken==persistedAuthToken) && persistedAuthToken),
    users: users ? JSON.parse(users) : [],
    persistedAuthToken,
    isAuthenticating: (authToken || (persistedAuthToken && users && JSON.parse(users).find(({authToken}={}) => authToken==persistedAuthToken))) ? true : false,
    login: {},
    ...(authToken ? {} : {isLoggedIn: false, user: undefined})
  },
});

export const setAuthToken = (authToken) => ({
  type: actions.setAuthToken,
  payload: {authToken},
});

export const getUsers = (users=localStorage.getItem('auth.users')) => ({
  type: actions.getUsers,
  payload: {
    users: users ? JSON.parse(users) : [],
  }
});

export const setUser = (user, error) => ({
  type: actions.setUser,
  payload: {
    user,
    error,
    isLoggedIn: (user) ? true : false,
    isAuthenticating: false,
  }
});

export const setUserCompany = (userCompany, error) => ({
  type: actions.setUserCompany,
  payload: {
    data: userCompany,
    error,
  }
});

export const setEnterprisePlan = ({record:{features=[], status='inactive', created}={}}={}, error) => ({
  type: actions.setEnterprisePlan,
  payload: {
    data: features.reduce((final, {name, ...feature}) => {
      final[name] = feature; 
      return final;
    }, {isActive: (status == 'active'), subscriptionDate: created}),
    error,
  }
});

export const login = ({email, password}) => ({
  type: actions.login,
  payload: {email, password},
});

export const loginFailed = (error) => ({
  type: actions.loginFailed,
  payload: {error},
});

export const loginSuccess = (authToken) => getAuthToken(authToken);


export const signUp = (userData) => ({
  type: actions.signUp,
  payload: userData
});

export const signUpSuccess = () => ({
  type: actions.signUpSuccess,
  payload: {}
});

export const signUpFailed = (error) => ({
  type: actions.signUpFailed,
  payload: {error}
});


export const logout = () => ({
  type: actions.logout,
});

export const forgotPassword = ({email}) => ({
  type: actions.forgotPassword,
  payload: {email},
});

export const generateResetPasswordToken = () => ({
  type: actions.generateResetPasswordToken,
});

export const getResetPasswordToken = ({_id, otp}) => ({
  type: actions.getResetPasswordToken,
  payload: {_id, otp},
});

export const setResetPasswordToken = ({_id=undefined, token=undefined, error=undefined}={}) => ({
  type: actions.setResetPasswordToken,
  payload: {_id, token, error},
});

export const setPassword = ({error=undefined, success=false, ...payload}={}) => ({
  type: actions.setPassword,
  payload: {error, success, ...payload},
});

export const updatePassword = ({token, password}) => ({
  type: actions.updatePassword,
  payload: {token, password},
});

export const getUserTokenEpic = action$ => action$.pipe(
  ofType(actions.getAuthToken),
  first(),
  switchMap(action => fromEvent(window, 'storage').pipe(
    filter(({key}) => key=='authToken'),
    map(({newValue}) => getAuthToken(newValue))
  ))
);

export const setAuthTokenEpic = (action$, state$) => action$.pipe(
  ofType(actions.setAuthToken),
  withLatestFrom(state$),
  map(([{payload: {authToken}={}}={}, {auth: {users=[]}={}}={}]) => {
    sessionStorage.setItem('authToken', authToken);
    localStorage.setItem('auth.persistedAuthToken', authToken);
    return getAuthToken();
  }),
);

export const getUsersEpic = action$ => action$.pipe(
  ofType(actions.getUsers),
  first(),
  switchMap(action => fromEvent(window, 'storage').pipe(
    filter(({key}) => key=='auth.users'),
    map(({newValue}) => {
      return getUsers(newValue);
    })
  ))
);

export const authenticateEpic = action$ => action$.pipe(
  ofType(actions.getAuthToken),
  filter(({payload: {authToken}}) => authToken),
  switchMap(({payload: {authToken}}) => ajax({
    url: '/api/auth/v2/user/',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    }
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setUser(undefined, error.message || error.toString() || error);
      if (!success || !data)
        return setUser(undefined, 'Unexpected error');
      return setUser(data);
    }),
    catchError(error => of(setUser(undefined, error.toString() || error)))
  ))
);

export const getUserCompanyEpic = (action$, state$) => action$.pipe(
  ofType(actions.setUser),
  withLatestFrom(state$),
  filter(([{payload: {user: {userCompany}={}}={}}, {auth: {authToken}={}}]) => authToken && !!userCompany),
  switchMap(([{payload: {user: {userCompany}={}}={}}, {auth: {authToken}={}}]) => ajax({
    url: `/api/shippers/${userCompany}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    }
  }).pipe(
    map(({response: {data:{record: data}={}, error, success}={}}) => {
      if (error)
        return setUserCompany(undefined, error.message || error.toString() || error);
      if (!success || !data)
        return setUserCompany(undefined, 'Unexpected error');
      return setUserCompany(data);
    }),
    catchError(error => of(setUserCompany(undefined, error.toString() || error)))
  ))
);

export const getEnterprisePlanEpic = (action$, state$) => action$.pipe(
  ofType(actions.setUser),
  withLatestFrom(state$),
  filter(([{payload: {user: {userCompany}={}}={}}, {auth: {authToken}={}}]) => authToken && !!userCompany),
  switchMap(([{payload: {user: {userCompany}={}}={}}, {auth: {authToken}={}}]) => ajax({
    url: `/api/enterprises/v2/subscription`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    }
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setEnterprisePlan(undefined, error.message || error.toString() || error);
      if (!success || !data)
        return setEnterprisePlan(undefined, 'Unexpected error');
      return setEnterprisePlan(data);
    }),
    catchError(error => of(setEnterprisePlan(undefined, error.toString() || error)))
  ))
);

export const loginEpic = (action$, state$) => action$.pipe(
  ofType(actions.login),
  withLatestFrom(state$),
  switchMap(([{payload}, {auth: {users=[]}={}}={}]) => ajax({
    url: '/api/auth/v2/login/',
    method: 'POST',
    body: {data: payload},
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return loginFailed(error.message || error.toString() || error);
      if (!success || !data || !data.authToken)
        return loginFailed('Unexpected Error: Try again later');
      sessionStorage.setItem('authToken', data.authToken);
      localStorage.setItem('auth.persistedAuthToken', data.authToken);
      localStorage.setItem('auth.users', JSON.stringify([...users.filter(({email}) => email!=payload.email), {
        email: payload.email,
        authToken: data.authToken,
      }]));
      return;
    }),
    catchError(error => of(loginFailed('Unexpected Error: Try again later'))),
    mergeMap(value => {
      if (value && value.type)
        return of(value);
      return from([loginSuccess(), getUsers()])
    }),
  ))
);

export const signUpEpic = (action$, state$) => action$.pipe(
  ofType(actions.signUp),
  withLatestFrom(state$),
  switchMap(([{payload}]) => ajax({
    url: '/api/users/create-profile',
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data:{record:data, message}={}, error, success}={}}) => {
      console.log(data, error, success);
      if (error||message)
        return signUpFailed(message || error.message || error.toString() || error);

      if (!success || !data || !data.id)
        return signUpFailed('Unexpected Error: Try again later');
      
      return signUpSuccess(data);
    })
  ))
)
export const logoutEpic = (action$, state$) => action$.pipe(
  ofType(actions.logout),
  withLatestFrom(state$),
  map(([_, {auth: {authToken: authTokenToRemove, users=[]}={}}={}]) => {
    sessionStorage.removeItem('authToken');
    localStorage.removeItem('auth.persistedAuthToken');
    localStorage.setItem('auth.users', JSON.stringify(users.filter(({authToken}) => authToken!=authTokenToRemove)));
    return;
  }),
  mergeMap(value => from([getAuthToken(), getUsers()])),
);

export const logoutFromOtherTabEpic = (action$, state$) => action$.pipe(
  ofType(actions.getUsers),
  withLatestFrom(state$),
  filter(([_, {auth: {authToken, users=[]}={}}]) => !users.find(({authToken: token}) => authToken==token)),
  mergeMap(_ => {
    sessionStorage.removeItem('authToken');
    return of(getAuthToken());
  }),
);

export const forgotPasswordEpic = action$ => action$.pipe(
  ofType(actions.forgotPassword),
  switchMap(({payload: {email}}) => ajax({
    url: '/api/auth/v2/forgot-password/',
    method: 'POST',
    body: {data: {email}},
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setResetPasswordToken({error: error.message || error.toString() || error});
      if (!success || !data)
        return setResetPasswordToken({error: 'Unexpected error'});
      return setResetPasswordToken(data);
    }),
    catchError(error => of(setResetPasswordToken({error: error.toString() || error})))
  ))
);

export const generateResetPasswordTokenEpic = (action$, state$) => action$.pipe(
  ofType(actions.generateResetPasswordToken),
  withLatestFrom(state$),
  switchMap(([_, {auth: {authToken}={}}={}]) => ajax({
    url: '/api/auth/v2/generate-reset-password-token/',
    method: 'POST',
    body: {},
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setResetPasswordToken({error: error.message || error.toString() || error});
      if (!success || !data)
        return setResetPasswordToken({error: 'Unexpected error'});
      return setResetPasswordToken(data);
    }),
    catchError(error => of(setResetPasswordToken({error: error.toString() || error})))
  ))
);

export const getResetPasswordTokenEpic = action$ => action$.pipe(
  ofType(actions.getResetPasswordToken),
  switchMap(({payload: {_id, otp}}) => ajax({
    url: '/api/auth/v2/get-password-token/',
    method: 'POST',
    body: {data: {_id, otp}},
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setResetPasswordToken({error: error.message || error.toString() || error});
      if (!success || !data || !data.record)
        return setResetPasswordToken({error: 'Unexpected error'});
      return setResetPasswordToken(data.record);
    }),
    catchError(error => of(setResetPasswordToken({error: error.toString() || error})))
  ))
);

export const updatePasswordEpic = action$ => action$.pipe(
  ofType(actions.updatePassword),
  switchMap(({payload: {token, password}}) => ajax({
    url: '/api/auth/v2/set-password/',
    method: 'POST',
    body: {data: {token, password}},
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(({response: {data, error, success}={}}) => {
      if (error)
        return setPassword({error: error.message || error.toString() || error});
      if (!success || !data)
        return setPassword({error: 'Unexpected error'});
      if (data.message)
        console.log(data.message);
        setSnackbar({message: data.message});
      return setPassword({success});
    }),
    catchError(error => of(setPassword({error: error.toString() || error})))
  ))
);
