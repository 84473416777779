import { combineReducers } from 'redux';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'ratesV3',
  url: '/api/rates/v3/rateInventory/filteredRates',
});

const { reducer: $chargeTypesList, actions: chargeTypesList, epics: chargeTypesListEpics } = listDux({
  name: 'ratesV3.$chargeTypes',
  url: '/api/rates/v3/chargetype/',
});

export const actions = { list, chargeTypesList, };
export const epics = [...listEpics, ...chargeTypesListEpics,];
export default combineReducers({ $list, $chargeTypesList, });