import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';

const { reducer: $addFeedback, actions: addFeedback, epics: addFeedbackEpics } = updateDux({
  name: 'feedback',
  url: '/api/enquiries/v2/feedback/',
});

export const actions = { addFeedback };
export const epics = [...addFeedbackEpics];
export default combineReducers({ $addFeedback });