import { setDetailsFromListEpic, getDetailsOnUpdateEpic, getDetailsOnCreateEpic, ajaxEpic } from './epic';
import dux from './dux';

const detailsDux = ({
  name,
  storeName = `${name}.$details`,
  initialState = {},
  actions = [],
  epics = [],
  idField = '_id',
  getDetailsPayload = (_id) => ({ [idField]: _id, isFetching: true, }),
  setDetailsPayload = ({ record, error = undefined, errorMessage = '', ...payload } = {}) => ({ ...payload, ...(record ? { [idField]: record[idField], record } : {}), error, errorMessage, isFetching: false, }),
  unsetDetailsPayload = (_id) => ({ [idField]: _id, record: undefined}),
  setDetailsFromListPayload = ({ [idField]: _id, record } = {}) => ({ [idField]: _id, record, isFetching: false }),
  reducer = ({ initialState, isOwnAction }) => (state = initialState, { type, payload: { [idField]: _id='default', ...details } = {} } = {}) => {
    if (!_id || !isOwnAction(type))
      return state;
    const { [_id]: oldDetails = {} } = state;
    const newDetails = { ...oldDetails, ...details, [idField]: _id };
    return {
      ...state,
      default: newDetails,
      [_id]: newDetails,
    }
  },
  setListActionType = `${name}.$list.set`,
  setUpdateActionType = `${name}.$update.set`,
  setCreateActionType = `${name}.$create.set`,
  withLatestState = true,
  url,
  method = 'GET',
  headers = {},
  request = { url, method, headers },
  getUrl = (url, { 0: { payload: { [idField]: _id } = {} } = {} }) => `${url}/${_id}`,
  getRequest,
  processResponse,
  processError,
  getOutAction,
  ...props
}) => dux({
  name: storeName,
  initialState,
  actions: [
    { name: 'setFromList', getPayload: setDetailsFromListPayload },
    { name: 'get', getPayload: getDetailsPayload },
    { name: 'set', getPayload: setDetailsPayload },
    { name: 'unset', getPayload: unsetDetailsPayload },
    ...actions,
  ],
  epics: [
    ({ getAction }) => setDetailsFromListEpic({ idField, setListActionType, setDetailsAction: getAction('setFromList') }),
    ({ getAction }) => getDetailsOnUpdateEpic({ idField, setUpdateActionType, getDetailsAction: getAction('get') }),
    ({ getAction }) => getDetailsOnCreateEpic({ idField, setCreateActionType, getDetailsAction: getAction('get') }),
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('get'), outAction: getAction('set'), withLatestState, request, getUrl, getRequest, processResponse, processError, getOutAction, }),
    ...epics,
  ],
  reducer,
  ...props,
});

export default detailsDux;