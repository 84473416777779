import { combineReducers } from 'redux';
import objectToQueryString from '../utils/objectToQueryString';
import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';
import createDux from './lib/createDux';

 const { reducer: $purchaseOrders, actions: purchaseOrders, epics: purchaseOrdersEpics } = culdDux({
  name: 'purchaseOrders',
  url: '/api/purchaseOrders/purchaseOrders',
});

const { reducer: $uploadPurchaseOrderFile, actions: uploadPurchaseOrderFile, epics: uploadPurchaseOrderFileEpics } = createDux({
  name: 'purchaseOrders.upload',
  url: '/api/purchaseOrders/purchaseOrders',
  headers: { 'Content-Type': 'FormData'},
});

const { reducer: $companies, actions: companies, epics: companiesEpics } = dux({
  name: 'purchaseOrders.companyIds',
  initialState: { isFetching: false, },
  actions: [
    { name: 'get', getPayload: (query) =>({query, isFetching: true })},
    { name: 'set', getPayload: ({ error, errorMessage = '', companyIds, ...rest }) => ({ error, errorMessage,  companies, ...rest, isFetching: false }) },
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: { url: '/api/purchaseOrders/companiesId/', method: 'GET' },
    getUrl: (url, {0: {payload: {query}={}}={}}=[]) => `${url}?${objectToQueryString(query)}`,
  }),
  ],
});

const { reducer: $suppliers, actions: suppliers, epics: suppliersEpics } = dux({
  name: 'purchaseOrders.suppliers',
  initialState: { isFetching: false, },
  actions: [
    { name: 'get', getPayload: (query) =>({query, isFetching: true })},
    { name: 'set', getPayload: ({ error, errorMessage = '', suppliers, ...rest }) => ({ error, errorMessage, suppliers, ...rest, isFetching: false }) },
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: { url: '/api/purchaseOrders/suppliers/', method: 'GET' },
    getUrl: (url, {0: {payload: {query}={}}={}}=[]) => `${url}?${objectToQueryString(query)}`,
  }),
  ],
});

const { reducer: $companyMaster, actions: companyMaster, epics: companyMasterEpics } = culdDux({
  name: 'companyMaster',
  url: '/api/purchaseOrders/companyMaster',
});

const { reducer: $bulkPurchaseOrder, actions: bulkPurchaseOrder, epics: bulkPurchaseOrderEpics } = culdDux({
  name: 'bulkPurchaseOrder',
  url: '/api/purchaseOrders/bulkPurchaseOrder/',
});

const { reducer: $bulkPurchaseOrderLogs, actions: bulkPurchaseOrderLogs, epics: bulkPurchaseOrderLogsEpics } = culdDux({
  name: 'bulkPurchaseOrderlog',
  url: '/api/purchaseOrders/bulkPurchaseOrderLogs/',
});

export const actions = { purchaseOrders, uploadPurchaseOrderFile, companies, suppliers, companyMaster, bulkPurchaseOrder, bulkPurchaseOrderLogs };
export const epics = [ ...purchaseOrdersEpics, ...uploadPurchaseOrderFileEpics, ...companiesEpics, ...suppliersEpics, ...companyMasterEpics, ...bulkPurchaseOrderEpics, ...bulkPurchaseOrderLogsEpics ];
export default combineReducers({ $purchaseOrders, $uploadPurchaseOrderFile, $companies, $suppliers, $companyMaster, $bulkPurchaseOrder, $bulkPurchaseOrderLogs });
