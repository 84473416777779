import { combineReducers } from 'redux';
import detailsDux from './lib/detailsDux';
import culdDux from './lib/culdDux';

const { reducer: $emissionDetails, actions: emissionDetails, epics: emissionDetailsEpics } = detailsDux({
  name: 'emission',
  url: '/api/emission',
});


export const actions = { ...emissionDetails,};
export const epics = [...emissionDetailsEpics,];
export default combineReducers({ $emissionDetails,});