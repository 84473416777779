import { combineReducers } from 'redux';

import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import listDux from './lib/listDux';
import updateDux from './lib/updateDux';

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'regularizationBills',
  url: '/api/regularization/bills',
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'regularizationBills',
  url: '/api/regularization/bills',
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'regularizationBills',
  url: '/api/regularization/bills',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'regularizationBills',
  url: '/api/regularization/bills',
});

const { reducer: $validateRegularizationBill, actions: validateRegularizationBill, epics: validateRegularizationBillEpics } = updateDux({
  name: 'regularizationBills.validate',
  url: '/api/regularization/bills/validate',
});

const { reducer: $pushRegularizationBill, actions: pushRegularizationBill, epics: pushRegularizationBillEpics } = updateDux({
  name: 'regularizationBills.push',
  url: '/api/regularization/bills/push',
});


export const actions = { list, details, create, update, validateRegularizationBill, pushRegularizationBill };
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics, ...validateRegularizationBillEpics, ...pushRegularizationBillEpics];
export default combineReducers({ $list, $details, $create, $update, $validateRegularizationBill, $pushRegularizationBill });