import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import updateDux from './lib/updateDux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'documents',
  url: '/api/documents/v2/'
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'documents',
  url: '/api/documents/v2/',
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'documents',
  url: '/api/documents/v2/',
  createdRecordField: 'document',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'documents',
  url: '/api/documents/v2',
  updatedRecordField: 'document',
});

const { reducer: $templateList, actions: templateList, epics: templateListEpics } = listDux({
  name: 'templateList',
  url: '/api/documents/v2/templates'
});

const { reducer: $templateDetail, actions: templateDetail, epics: templateDetailEpics } = detailsDux({
  name: 'templateDetail',
  url: '/api/documents/v2/templates',
});

const { reducer: $templateCreate, actions: templateCreate, epics: templateCreateEpics } = createDux({
  name: 'templateCreate',
  url: '/api/documents/v2/templates',
});

const { reducer: $templateUpdate, actions: templateUpdate, epics: templateUpdateEpics } = updateDux({
  name: 'templateUpdate',
  url: '/api/documents/v2/templates',
});

const { reducer: $deleteDoc, actions: deleteDoc, epics: deleteDocEpics } = updateDux({
  name: 'documents.delete',
  url: '/api/documents/v2/delete'
});

const { reducer: $kycDocumentList, actions: kycDocumentList, epics: kycDocumentListEpics } = listDux({
  name: 'kycDocuments',
  url: '/api/documents/v2/'
});

const { reducer: $documentTypeList, actions: documentTypeList, epics: documentTypeListEpics } = listDux({
  name: 'documentType',
  url: '/api/documents/v2/documentType'
});

const { reducer: $documentTypeDetails, actions: documentTypeDetails, epics: documentTypeDetailsEpics } = detailsDux({
  name: 'documentType',
  url: '/api/documents/v2/documentType',
});

const { reducer: $updateDocumentType, actions: updateDocumentType, epics: updateDocumentTypeEpics } = updateDux({
  name: 'documentType',
  url: '/api/documents/v2/documentType',
});

const { reducer: $shipmentList, actions: shipmentList, epics: shipmentListEpics } = listDux({
  name: 'documentShipments',
  url: '/api/documents/v2/shipments'
});

const { reducer: $getComments, actions: getComments, epics: getCommentsEpics } = listDux({
  name: 'documentComments',
  url: '/api/documents/v2/comments'
});

const { reducer: $addComment, actions: addComment, epics: addCommentEpics } = updateDux({
  name: 'documentComments',
  url: '/api/documents/v2/comment'
});

const { reducer: $uploadFile, actions: uploadFile, epics: uploadFileEpics } = updateDux({
  name: 'documents.upload',
  updatedRecordField: 'document',
  url: '/api/documents/v2/upload',
  headers: { 'Content-Type': 'FormData'},
});

export const actions = { list, details, uploadFile, getComments, addComment, deleteDoc, documentTypeList, documentTypeDetails, updateDocumentType, create, update, kycDocumentList, shipmentList, templateDetail, templateList, templateUpdate, templateCreate, };
export const epics = [...listEpics, ...detailsEpics, ...uploadFileEpics, ...getCommentsEpics, ...addCommentEpics, ...deleteDocEpics, ...documentTypeListEpics, ...documentTypeDetailsEpics, ...updateDocumentTypeEpics, ...createEpics, ...updateEpics, ...kycDocumentListEpics, ...shipmentListEpics, ...templateListEpics, ...templateDetailEpics, ...templateCreateEpics, ...templateUpdateEpics, ];
export default combineReducers({ $list, $details, $uploadFile, $getComments, $addComment, $deleteDoc, $documentTypeList, $documentTypeDetails, $updateDocumentType, $create, $update, $kycDocumentList, $shipmentList, $templateCreate, $templateDetail, $templateList, $templateUpdate, });
