import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'manifestsCreate',
  url: '/api/manifest/'
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'manifestsDetail',
  url: '/api/manifest/'
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'manifestsUpdate',
  url: '/api/manifest/',
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'manifestsList',
  url: '/api/manifest/'
});

export const actions = { create, update, list, details };
export const epics = [...createEpics, ...updateEpics, ...listEpics, ...detailsEpics ];
export default combineReducers({ $create, $update, $list, $details });