import { combineReducers } from 'redux';
import objectToQueryString from '../utils/objectToQueryString';

import dux from './lib/dux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';
import {ajaxEpic} from './lib/epic';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
  setDetailsFromListPayload: () => { },
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
  switchRequests: false,
  // withLatestState: false,
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'rfqQuotes.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/rfq/quotes/summary', method: 'GET'},}),
  ],
});

const { reducer: $approveQuote, actions: approveQuote, epics: approveQuoteEpics } = updateDux({
  name: 'rfqQuotes.$approveQuote',
  url: '/api/rfq/quotes/approve',
});

const { reducer: $rejectQuote, actions: rejectQuote, epics: rejectQuoteEpics } = updateDux({
  name: 'rfqQuotes.$rejectQuote',
  url: '/api/rfq/quotes/reject',
});
const { reducer: $requestQuoteApproval, actions: requestQuoteApproval, epics: requestQuoteApprovalEpics } = updateDux({
  name: 'rfqQuotes.$requestQuoteApproval',
  url: '/api/rfq/quotes/request-approval',
});

const { reducer: $history, actions: history, epics: historyEpics } = listDux({
  name: 'rfqQuotes.$history',
  url: '/api/rfq/quotes/',
  getUrl: (url, { 0: { payload: { query: { quote, ...query } } = {} } = {} }) => `${url}${quote}/history?${objectToQueryString(query)}`,
});

export const actions = { list, details, create, update, summary, approveQuote, rejectQuote, requestQuoteApproval, history };
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics, ...summaryEpics, ...approveQuoteEpics, ...rejectQuoteEpics, ...requestQuoteApprovalEpics, ...historyEpics];
export default combineReducers({ $list, $details, $create, $update, $summary, $approveQuote, $rejectQuote, $requestQuoteApproval, $history });
