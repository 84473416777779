import React from 'react';
import { withMediaQueryProps } from '../media-query-2';
import { mediaQuery } from '../media-query';

import * as Fonts from '../theme/Fonts.css';

function Cell({ children, flexGrow, flexShrink, alignSelf, mediaQuery={}, font, style={}, className='', ...rest }, ref) {
  style = {
    boxSizing: 'border-box',
    flexGrow,
    flexShrink,
    alignSelf,
    ...style,
  };
  className = `${font ? `${Fonts[font]} ` : ''}${className}`;
  
  return (
    <div {...{ ref, style, className, ...rest }}>{children}</div>
  );
}

export default withMediaQueryProps(mediaQuery(React.forwardRef(Cell)));
