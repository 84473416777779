import objectToQueryString from '../utils/objectToQueryString';

import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';


const { reducer: $watchTower, actions: watchTower, epics: watchTowerEpics } = listDux({
  name: 'watchTower',
  url: '/api/tracking_insights/containers-at-pod',
});

const { reducer: $multiModel, actions: multiModel, epics: multiModelEpics } = listDux({
  name: 'multiModel',
  url: '/api/tracking_insights/',
});

const { reducer: $trackingModel, actions: trackingModel, epics: trackingModelEpics } = detailsDux({
  name: 'trackingModel',
  url: '/api/tracking_insights/',
});

const { reducer: $shareTracking, actions: shareTracking, epics: shareTrackingEpics } = detailsDux({
  name: 'shareTracking',
  url: '/api/tracking_insights',
  getUrl: (url, {0: {payload: {_id}={}}={}}=[]) => `${url}/${_id}/share-tracking/`,
});

const { reducer: $shareLink, actions: shareLink, epics: shareLinkEpics } = detailsDux({
  name: 'shareLink',
  url: '/api/tracking/share-link',
  getListPayload : (query = {}) => ({ query, [JSON.stringify(query)]: { isFetching: true,}, isFetching: true,}),
  getUrl: (url, {0: {payload: {query}={}}={}}=[]) => `${url}/share-tracking/?${objectToQueryString(query)}`,

});

const { reducer: $trackingSubscriptionList, actions: trackingSubscriptionList, epics: trackingSubscriptionListEpics } = listDux({
  name: 'trackingSubscriptionList',
  url: '/api/trackingmore/subscriptions',
});

const { reducer: $trackingSubscriptionByReference, actions: trackingSubscriptionByReference, epics: trackingSubscriptionByReferenceEpics } = listDux({
  name: 'trackingSubscriptionByReference',
  url: '/api/trackingmore/subscriptions',
  setDetailsFromListPayload: () => ({}),
  getUrl: (url, {0: {payload: {query}={}}={}}=[]) => {
    console.log(query)
    return `${url}/reference/?${objectToQueryString(query)}`
  },
});

const { reducer: $trackingSubscription, actions: trackingSubscription, epics: trackingSubscriptionEpics } = detailsDux({
  name: 'trackingSubscription',
  url: '/api/trackingmore/subscriptions',
});


export const actions = { watchTower, multiModel, shareTracking, shareLink, trackingSubscriptionList, trackingSubscriptionByReference, trackingSubscription,trackingModel};
export const epics = [...watchTowerEpics, ...multiModelEpics, ...shareTrackingEpics, ...shareLinkEpics, ...trackingSubscriptionListEpics, ...trackingSubscriptionEpics, ...trackingSubscriptionByReferenceEpics,...trackingModelEpics];
export default combineReducers({ $watchTower, $multiModel , $shareTracking, $shareLink, $trackingSubscriptionList, $trackingSubscriptionByReference, $trackingSubscription, $trackingModel});