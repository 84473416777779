import { combineReducers } from 'redux';
import detailsDux from './lib/detailsDux';

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'invoices',
  url: '/api/shipments/v2/shipment-invoices/'
});

export const actions = { details, };
export const epics = [ ...detailsEpics, ];
export default combineReducers({ $details, });