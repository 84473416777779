import { combineReducers } from 'redux';

import listDux from './lib/listDux';


const { reducer: $oceanioList, actions: oceanioList, epics: oceanioListEpics } = listDux({
  name: 'oceanio',
  url: '/api/oceanio/events/milestone',
});

export const actions = { oceanioList };
export const epics = [...oceanioListEpics,];
export default combineReducers({ $oceanioList });