
import {first, map, withLatestFrom,} from 'rxjs/operators';
import {ofType} from 'redux-observable';


export const actions = {
  getSearchHistory: 'search.getSearchHistory',
  setSearchHistory: 'search.setSearchHistory',
}


export default function reducer (state = {
  searches: [],
  search: {},
}, {type, payload={}}) {
  switch(type) {
    case actions.getSearchHistory:
      return {
        ...state,
        ...payload
      }
    case actions.setSearchHistory:
      return {
        ...state,
        ...payload,
      }
    default: 
      return state;
  }
}


export const getSearchHistory = (searches) => {
  return ({
    type: actions.getSearchHistory,
    payload: {
      searches,
    }
  })
};

export const setSearchHistory = ({search}) => ({
  type: actions.setSearchHistory,
  payload: {
    search,
  }
});

export const getSearchHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getSearchHistory),
  first(),
  withLatestFrom(state$),
  map(([_, {auth:{user:{id: userId}={}}={}}={}]) => {
    const searchHistory = JSON.parse(localStorage.getItem('search.history'))||[];
    return getSearchHistory(((searchHistory.find(({id}) => id == userId)||{}).searches)||[]);
  })
);



export const setSearchHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.setSearchHistory),
  withLatestFrom(state$),
  map(([{payload:{search={}}}, {auth:{user:{id: userId}={}}={}, searchDialog:{searches=[]}}={}]) => {
    if (!search.text || searches.map(({text}) => text).includes(search.text))
      return getSearchHistory(searches);
    const searchHistory = JSON.parse(localStorage.getItem('search.history'))||[];
    const userSearches = (searchHistory.find(({id}) => id == userId)||{}).searches||[];
    const userSearchIndex = searchHistory.findIndex(({id}) => id == userId);
    if (!userSearches.map(({text}) => text).includes(search.text))
      localStorage.setItem('search.history', JSON.stringify([...searchHistory.slice(0, userSearchIndex), {id: userId, searches:[search, ...userSearches].slice(0,5)}, ...searchHistory.slice(userSearchIndex+1)]));
    return getSearchHistory([search, ...userSearches].slice(0,5))
  })
);

