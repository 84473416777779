import { combineReducers } from 'redux';

import listDux from './lib/listDux';;

const { reducer: $shipmentEvents, actions: shipmentEvents, epics: shipmentEventsEpics } = listDux({
  name: 'shipmentEvents',
  url: '/api/events/shipment',
  getUrl: (url, {0: {payload: {query:id}={}}={}}=[]) => `${url}/${id}`, 
});

export const actions = {shipmentEvents,};
export const epics = [...shipmentEventsEpics,];
export default combineReducers({$shipmentEvents,});