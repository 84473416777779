import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';


const { reducer: $negotiations, actions: negotiations, epics: negotiationEpics } = culdDux({
  name: 'negotiations',
  url: '/api/rfq/negotiations',
});



export const actions = { ...negotiations,};
export const epics = [...negotiationEpics, ];
export default combineReducers({ $negotiations,});