import * as utils from './utils';

const {all, one, not, isLoggedIn, isLoggedOut, isProtected, hideHeader, hideSideNav, hideFooter, hideNotification, hasAccess} = utils;

const getNonRoutingApps =  routes => ({
  login: {
    activeWhen: all(isProtected(routes), isLoggedOut),
    customProps: {instance: 'all',},
  },
  sideNav: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideSideNav(routes))),
    customProps: {instance: 'all',},
  },
  header: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideHeader(routes))),
    customProps: {instance: 'all',},
  },
  footer: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideFooter(routes))),
    customProps: {instance: 'all',},
  },
  notification: {
    activeWhen: all(isProtected(routes), isLoggedIn, not(hideNotification(routes))),
    customProps: {instance: 'all',},
  },
  snackbar: {
    activeWhen: one(()=>(true)),
    customProps: {instance: 'all',},
  }
});

export default getNonRoutingApps;