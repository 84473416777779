import * as utils from './utils';

const {one, all, not, includes, excludes, isLoggedIn, routeEquals, routeStartsWith} = utils;

const getRoutingApps = routes => routes.reduce((apps, {app, name=app, equals, startsWith, isProtected, exclude={}, customProps={}}) => {
  const {[name]: {activeWhen: activeWhen$, customProps: customProps$={}}={}} = apps;
  return {
    ...apps,
    [name]: {
      app,
      activeWhen: all(...[
        one(...[
          includes({equals, startsWith}),
          ...(activeWhen$ ? [activeWhen$] : []),
        ]),
        excludes(exclude),
        ...(isProtected ? [isLoggedIn] : []),
      ]),
      customProps: {
        ...customProps$,
        ...customProps,
      },
    },
  }
}, {});

export default getRoutingApps;