import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';
import {setSnackbar} from '../react-components/snackbar';

const {reducer: $create, actions: create, epics: createEpics} = createDux({
  name: 'iciciLead',
  url: '/api/webforms/icici/',
});

const {reducer: $update, actions: update, epics: updateEpics} = updateDux({
  name: 'iciciLeadUpdate',
  url: '/api/webforms/icici/',
  processResponse: ([{response: {success, error, data}}]) => {
    setSnackbar({message: success ? 'Updated Lead': (error.message || 'retry')});
    return {success, error, data};
  },
});

const {reducer: $list, actions: list, epics: listEpics} = listDux({
  name: 'iciciLeadList',
  url: '/api/webforms/icici/',
});

const {reducer: $createUser, actions: createUser, epics: createUserEpics} = createDux({
  name: 'createUserFromICICILead',
  url: '/api/webforms/icici/create-user',
  createPayload: ({ name, email, companyName: company, number, }) => ({
    data: {
      name,
      email,
      company,
      number,
      createdVia: 'LeadEngine',
    },
    isCreating: true,
  }),
  processResponse: ([{response: {success, data}}]) => {
    const error = success ? undefined : data;
    setSnackbar({message: success ? 'User Account Created': ((error?.message) || 'failed to create account')});
    return ({success, error, data});
  },
});

export const actions = { create, list, update,  createUser, };
export const epics = [...createEpics, ...listEpics, ...updateEpics, ...createUserEpics,];
export default combineReducers({ $create, $list, $update, $createUser, });
