import React from 'react';
import { withMediaQueryProps} from '../media-query-2';
import { mediaQuery } from '../media-query';

function Column({children, alignItems, mediaQuery={}, justifyContent, flexGrow, flexShrink, alignSelf, style={}, flexWrap, gap, className='', ...rest}, ref) {
  style = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems,
    justifyContent,
    flexGrow,
    flexShrink,
    flexWrap,
    alignSelf,
    gap,
    ...style,
  }
  return (
    <div {...{ref, style, className, ...rest}}>{children}</div>
  )
}

export default withMediaQueryProps(mediaQuery(React.forwardRef(Column)));