import listDux from './lib/listDux';
import { combineReducers } from 'redux';

const { reducer: $companyLocations, actions: list, epics: listEpic } = listDux({
  name: 'companyLocations',
  url: '/api/location/list-company-locations',
});

export const actions = { list };
export const epics = [ ...listEpic ];
export default combineReducers({ $companyLocations });