import { combineReducers } from 'redux';

import detailsDux from './lib/detailsDux';;
import listDux from './lib/listDux';
import updateDux from './lib/updateDux';

const { reducer: $userNotificationPreferencesDetail, actions: userNotificationPreferencesDetail, epics: userNotificationPreferencesDetailEpics } = detailsDux({
  name: 'userNotificationPreferencesDetail',
  url: '/api/events/user-notification-preferences',
});

const { reducer: $userNotificationPreferencesUpdate, actions: userNotificationPreferencesUpdate, epics: userNotificationPreferencesUpdateEpics } = updateDux({
  name: 'userNotificationPreferencesUpdate',
  url: '/api/events/user-notification-preferences',
});

const { reducer: $eventCategoryConfigurationList, actions: eventCategoryConfigurationList, epics: eventCategoryConfigurationListEpics } = listDux({
  name: 'eventCategoryConfigurationList',
  url: '/api/events/event-category-config',
});

const { reducer: $defaultUserNotificationPreferencesDetail, actions: defaultUserNotificationPreferencesDetail, epics: defaultUserNotificationPreferencesDetailEpics } = detailsDux({
  name: 'defaultUserNotificationPreferencesDetail',
  url: '/api/events/default-user-notification-preferences',
  idField: 'company',
});


const { reducer: $defaultUserNotificationPreferencesUpdate, actions: defaultUserNotificationPreferencesUpdate, epics: defaultUserNotificationPreferencesUpdateEpics } = updateDux({
  name: 'defaultUserNotificationPreferencesUpdate',
  url: '/api/events/default-user-notification-preferences',
  setUpdatePayload: ({ error = undefined, errorMessage = '', ...payload } = {}) => ({ record: payload, error, errorMessage, isUpdating: false }),
});


export const actions = {userNotificationPreferencesDetail, userNotificationPreferencesUpdate, eventCategoryConfigurationList, defaultUserNotificationPreferencesDetail, defaultUserNotificationPreferencesUpdate};
export const epics = [...userNotificationPreferencesDetailEpics, ...userNotificationPreferencesUpdateEpics, ...eventCategoryConfigurationListEpics, ...defaultUserNotificationPreferencesDetailEpics, ...defaultUserNotificationPreferencesUpdateEpics];
export default combineReducers({$userNotificationPreferencesDetail, $userNotificationPreferencesUpdate, $eventCategoryConfigurationList, $defaultUserNotificationPreferencesDetail, $defaultUserNotificationPreferencesUpdate});