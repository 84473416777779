import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';
import listDux from './lib/listDux';

const { reducer: $shortlistedItems, actions: shortlistedItems, epics: shortlistedItemEpics } = culdDux({
  name: 'shortlistedItems',
  url: '/api/dashboard/shortlistedItems',
});

const { reducer: $shortlistedShipments, actions: shortlistedShipments, epics: shortlistedShipmentsEpics } = listDux({
  name: 'shortlistedShipments',
  url: '/api/shipments/v2/'
});

export const actions = { shortlistedItems, shortlistedShipments };
export const epics = [...shortlistedItemEpics, ...shortlistedShipmentsEpics];
export default combineReducers({ $shortlistedItems, $shortlistedShipments });