import { combineReducers } from 'redux';
import {fromEvent, of, from, empty, combineLatest} from 'rxjs';
import {first, take, map, filter, mergeMap, switchMap, mapTo, withLatestFrom, catchError} from 'rxjs/operators';
import dux from './lib/dux';
import {epic} from './lib/epic';

const getComponentsEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('getComponents')},
  'first',
  switchMap(({payload: {components={}}}) => {
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  }),
]);
const setComponentsEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('setComponents')},
  'first',
  switchMap(_ => fromEvent(window, 'storage').pipe(
    filter(({key}) => key=='components'),
    map(({newValue}) => getAction('setComponents')(JSON.parse(newValue)))
  )),
]);
const cloneComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('cloneComponent')},
  'withLatestState',
  switchMap(([{payload: {id}={}}, {compose: {$components: {components={}}={}}={}}]) => {
    const cloneComponent = (id, newParent) => {
      const {[id]: existingComponent} = components;
      const newComponentId = Math.random();
      const children = (existingComponent.children || []).map(child => cloneComponent(child, newComponentId));
      const newComponent = {
        ...existingComponent,
        parent: newParent,
        children: children.map(({newComponentId}) => newComponentId),
        isCollapsed: true,
      }
      return {
        newComponentId, 
        newComponents: children.reduce((final, {newComponents={}}) => ({
          ...final,
          ...newComponents,
        }), {[newComponentId]: newComponent}),
      };
    }

    const {[id]: {parent: parentId, ...component}={}, [parentId]: {children=[], ...parent}={}} = components;
    if (!components[id] || !components[parentId])
      return empty();
    const {newComponentId, newComponents} = cloneComponent(id, parentId);
    const pos = children.indexOf(id) + 1;
    components = {
      ...components,
      ...newComponents,
      [id]: {...component, parent: parentId, isCollapsed: true,},
      [parentId]: {
        ...parent, 
        isCollapsed: false,
        children: [...children.slice(0, pos), newComponentId, ...children.slice(pos)],
      },
    }
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  })
]);
const createComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('createComponent')},
  'withLatestState',
  switchMap(([{payload: {id=Math.random(), component={}, beforeComponent, afterComponent}={}}, {compose: {$components: {components={}}={}}={}}]) => {
    const {parent: parentId='root'} = component;
    const {[parentId]: {children=[], ...parent}={}} = components;
    const pos = (beforeComponent ? children.indexOf(beforeComponent) : (afterComponent ? children.indexOf(afterComponent) + 1 : children.length));
    if (!parent)
      return empty();
    components = {
      ...components,
      [id]: {...component, parent: parentId},
      [parentId]: {
        ...parent, 
        isCollapsed: false,
        children: [...children.slice(0, pos), id, ...children.slice(pos)],
      },
    }
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  })
]);
const updateComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('updateComponent')},
  'withLatestState',
  switchMap(([{payload: {id, component={}}={}}, {compose: {$components: {components={}}={}}={}}]) => {
    const {[id]: oldComponent} = components;
    components = {...components, [id]: {...oldComponent, ...component}};
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  }),
]);
const deleteComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('deleteComponent')},
  'withLatestState',
  switchMap(([{payload: {id}}, {compose: {$components: {components={}}={}}={}}]) => {
    const {[id]: {parent: parentId}={}, [parentId]: {children=[], ...parent}={}} = components;
    components = {
      ...components, 
      [id]: undefined, 
      [parentId]: {
        ...parent,
        children: children.filter(child => child!=id),
      },
    };
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  })
]);
const moveComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('moveComponent')},
  'withLatestState',
  switchMap(([{payload: {id, parent='root', beforeComponent, afterComponent}={}}, {compose: {$components: {components={}}={}}={}}]) => {
    const checkIfParentIsChild = (id, parent) => {
      if (parent=='root')
        return false;
      if (!parent || parent==id)
        return true;
      return checkIfParentIsChild(id, (components[parent] || {}).parent);
    }
    if (checkIfParentIsChild(id, parent)) 
      return empty();
    const {
      [id]: {parent: oldParentId, ...component}={}, 
      [oldParentId]: oldParent={}, 
    } = components;
    components = {
      ...components, 
      [id]: {...component, parent}, 
      [oldParentId]: {
        ...oldParent,
        children: (oldParent.children || []).filter(child => child!=id),
      },
    };
    const {
      [parent]: {children=[], ...newParent}={}
    } = components;
    const pos = (beforeComponent ? children.indexOf(beforeComponent) : (afterComponent ? children.indexOf(afterComponent) + 1 : children.length));
    components = {
      ...components, 
      [parent]: {
        ...newParent, 
        isCollapsed: false,
        children: [...children.slice(0, pos), id, ...children.slice(pos)],
      },
    };
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  })
]);
const toggleHighlightedComponentEpic = ({getActionType, getAction}) => epic([
  {inActionType: getActionType('toggleHighlightedComponent')},
  'withLatestState',
  switchMap(([{payload: {id}={}}, {compose: {$components: {components: {highlightedComponent, ...components}={}}={}}={}}]) => {
    components = {...components, highlightedComponent: (highlightedComponent==id) ? null : id};
    localStorage.setItem('components', JSON.stringify(components));
    return of(getAction('setComponents')(components));
  })
]);

const {reducer: $components, actions: composeActions, epics: composeEpics} = dux({
  name: 'compose',
  initialState: {isFetching: false, error: null,},
  actions: [
    {name: 'getComponents', getPayload: () => ({
      components: localStorage.getItem('components') ? JSON.parse(localStorage.getItem('components')) : {
        root: {
          type: 'Fragment',
        },
        highlightedComponent: null,
      },
    }),},
    {name: 'setComponents', getPayload: (components) => ({isFetching: false,components,}),},
    {name: 'createComponent', getPayload: (component={}, {beforeComponent, afterComponent, id}={}) => ({id, component, beforeComponent, afterComponent,}),},
    {name: 'updateComponent', getPayload: (id, component) => ({id, component,}),},
    {name: 'deleteComponent', getPayload: (id) => ({id,}),},
    {name: 'moveComponent', getPayload: (id, parent, {beforeComponent, afterComponent}={}) => ({id, parent, beforeComponent, afterComponent,}),},
    {name: 'cloneComponent', getPayload: (id) => ({id}),},
    {name: 'toggleHighlightedComponent', getPayload: (id) => ({id}),},
  ],
  epics: [
    getComponentsEpic,
    setComponentsEpic,
    cloneComponentEpic,
    createComponentEpic,
    updateComponentEpic,
    deleteComponentEpic,
    moveComponentEpic,
    toggleHighlightedComponentEpic,
  ],
});

export const actions = composeActions;
export const epics = composeEpics;
export default combineReducers({$components});