import { combineReducers } from 'redux';

import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import {ajaxEpic} from './lib/epic';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'companies',
  url: '/api/companies/',
});

const { reducer: $filterCompanies, actions: filterCompanies, epics: filterCompaniesEpics } = listDux({
  name: 'companiesall',
  url: '/api/companies/all',
  processResponse: ([{ response = [], status }] = []) => {
    if (status == 200)
      return { items: response.data, count: response.data.length };
    return { error: 'Server Error', errorMessage: 'Server Error' };
  }
});


const { reducer: $shipperDetails, actions: shipperDetails, epics: shipperDetailsEpics } = detailsDux({
  name: 'companies',
  url: '/api/shippers/',
  setDetailsFromListPayload: () => ({})
});


// const { reducer: $kycDocuments, actions: kycDocuments, epics: kycDocumentsEpics } = listDux({
//   name: 'companiesKycDocuments',
//   url: '/api/companies/',
// });

const { reducer: $kycDocuments, actions: kycDocuments, epics: kycDocumentsEpics } = listDux({
  name: 'companiesKycDocuments',   
  actions: [
    {name: 'get', getPayload: ({id, docType}) => ({id, docType, isFetching: true})},
    {name: 'set', getPayload: (payload={}) => ({...payload, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({
      inActionType: getActionType('get'), 
      outAction: getAction('set'), 
      request: {
        url: '/api/companies/',
      }, 
      withLatestState: true,
      getUrl: (url, {0: {payload: {id, docType}={}}={}}=[]) => `${url}${id}/documents/${docType}`,
      switchRequests: false,
      // processResponse: ([{response: {data={}, error, success}={}}={}, {0: {payload: {}={}}={}}=[]]=[]) =>  ({
      //   ...(!success ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      //   ...(error ? {error, errorMessage: error.message || error.toString() || 'Server Error'} : {}),
      //   ...(data ? {items: data.record.items, count:data.record.count} : {}),
      // }),
    }),
  ]
});


export const actions = {list, shipperDetails,kycDocuments, filterCompanies};
export const epics = [...listEpics, ...shipperDetailsEpics,...kycDocumentsEpics, ...filterCompaniesEpics];
export default combineReducers({$list, $shipperDetails,$kycDocuments, $filterCompanies});