
import { combineReducers } from 'redux';

import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';

const { reducer: $requisitions, actions: requisitions, epics: requisitionEpics } = culdDux({
  name: 'requisitions',
  url: '/api/orders/requisitions',
});

const { reducer: $requisitionLineItems, actions: requisitionLineItems, epics: requisitionLineItemsEpics } = culdDux({
  name: 'requisitionLineItems',
  url: '/api/orders/requisition-line-items',
});

const { reducer: $lineItemUploads, actions: lineItemUploads, epics: lineItemUploadsEpics } = culdDux({
  name: 'lineItemUploads',
  url: '/api/orders/line-item-uploads',
  switchRequests: false,
});

const { reducer: $approvalUserConfigurations, actions: approvalUserConfigurations, epics: approvalUserConfifurationsEpics } = culdDux({
  name: 'approvalUserConfigurations',
  url: '/api/orders/approval-user-configurations',
});

const { reducer: $uploadFile, actions: uploadFile, epics: uploadFileEpics } = updateDux({
  name: 'lineItemUploads.upload',
  url: '/api/orders/line-item-uploads',
  getUrl: (url, { 0: { payload: { _id } = {} } = {} }) => `${url}/${_id}/upload/`,
  headers: { 'Content-Type': 'FormData'},
});

const { reducer: $summary, actions: summary, epics: summaryEpics } = dux({
  name: 'requisitions.$summary',
  initialState: { isFetching: false, },
  actions: [
    { name: 'get', getPayload: () => ({ isFetching: true }) },
    { name: 'set', getPayload: ({ error, errorMessage = '', statuses, ...rest }) => ({ error, errorMessage, summary: statuses, ...rest, isFetching: false }) },
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: { url: '/api/orders/requisitions/summary', method: 'GET' }, }),
  ],
});


export const actions = { requisitions, requisitionLineItems, lineItemUploads, approvalUserConfigurations, uploadFile, summary};
export const epics = [...requisitionEpics, ...requisitionLineItemsEpics, ...lineItemUploadsEpics, ...approvalUserConfifurationsEpics, ...uploadFileEpics, ...summaryEpics];
export default combineReducers({ $requisitions, $requisitionLineItems, $lineItemUploads, $approvalUserConfigurations, $uploadFile, $summary});
