import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';

const { reducer: $shipments, actions: shipments, epics: shipmentsEpics } = culdDux({
  name: 'shipments',
  url: '/api/shipments/v2/',
});

const { reducer: $shipmentInvoices, actions: shipmentInvoices, epics: shipmentInvoicesEpics } = listDux({
  name: 'shipmentInvoices',
  url: '/api/shipments/v2/shipment-invoices/'
});

const { reducer: $shipmentBls, actions: shipmentBls, epics: shipmentBlsEpics } = listDux({
  name: 'shipmentBl',
  url: '/api/shipments/v2/shipment-bl/'
});

const { reducer: $shipmentList, actions: shipmentList, epics: shipmentListEpics } = listDux({
  name: 'shipmentList',
  url: '/api/shipments/v2/',
});

const { reducer: $shipment, actions: shipment, epics: shipmentEpics } = detailsDux({
  name: 'shipment',
  url: '/api/shipments/v2/',
});

export const actions = { ...shipments, shipmentInvoices, shipmentBls, shipmentList, shipment};
export const epics = [...shipmentsEpics, ...shipmentInvoicesEpics, ...shipmentBlsEpics, ...shipmentListEpics, ...shipmentEpics];
export default combineReducers({ $shipments, $shipmentInvoices, $shipmentBls, $shipmentList, $shipment});