import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';

const { reducer: $shippingBillIndia, actions: shippingBillIndia, epics: shippingBillIndiaEpics } = createDux({
  name: 'pdfReader.shippingBillIndia',
  url: '/api/pdfReader/shippingBillIndia',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $billOfLading, actions: billOfLading, epics: billOfLadingEpics } = createDux({
  name: 'pdfReader.billOfLading',
  url: '/api/pdfReader/billOfLading',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $invoiceCreate, actions: invoiceCreate, epics: invoiceCreateEpics } = createDux({
  name: 'pdfReader.invoice',
  url: '/api/pdfReader/createInvoice',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $invoiceDetails, actions: invoiceDetails, epics: invoiceDetailsEpics } = detailsDux({
  name: 'pdfReader.invoice',
  url: '/api/pdfReader/getInvoice',
});

export const actions = { shippingBillIndia, billOfLading, invoiceCreate, invoiceDetails, };
export const epics = [...shippingBillIndiaEpics, ...billOfLadingEpics, ...invoiceCreateEpics, ...invoiceDetailsEpics];
export default combineReducers({ $shippingBillIndia, $billOfLading, $invoiceCreate, $invoiceDetails });