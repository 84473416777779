import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';
import dux from './lib/dux';
import {ajaxEpic} from './lib/epic';

const { reducer: $createUser, actions: createUser, epics: createUserEpics } = createDux({
  name: 'pay2corp.user',
  url: '/api/pay2corp/users/',
});

const { reducer: $userDetails, actions: userDetails, epics: userDetailsEpics } = detailsDux({
  name: 'pay2corp.user',
  url: '/api/pay2corp/users/',
});

const { reducer: $createTransaction, actions: createTransaction, epics: createTransactionEpics } = createDux({
  name: 'pay2corp.transaction',
  url: '/api/pay2corp/transactions/',
});

const { reducer: $transactionDetails, actions: transactionDetails, epics: transactionDetailsEpics } = detailsDux({
  name: 'pay2corp.transaction',
  url: '/api/pay2corp/transactions/',
});

const { reducer: $walletBalance, actions: walletBalance, epics: walletBalanceEpics } = createDux({
  name: 'pay2corp.balance',
  url: '/api/pay2corp/users/get-balance',
});

export const actions = { createUser, userDetails, createTransaction, transactionDetails, walletBalance };
export const epics = [...createUserEpics, ...userDetailsEpics, ...createTransactionEpics, ...transactionDetailsEpics, ...walletBalanceEpics];
export default combineReducers({$createUser, $userDetails, $createTransaction, $transactionDetails, $walletBalance});