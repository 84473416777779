import { combineReducers } from 'redux';
import {ajaxEpic} from './lib/epic';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'positions',
  url: '/api/positions',
  getUrl: (url, {0: {payload: {query:id}={}}={}}=[]) => `${url}/${id}`, 
});


export const actions = {list};
export const epics = [...listEpics,];
export default combineReducers({$list});