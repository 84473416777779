import { combineReducers } from 'redux';

import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';

const { reducer: $approvalUserConfigurations, actions: approvalUserConfigurations, epics: approvalUserConfifurationsEpics } = culdDux({
  name: 'approvalUserConfigurationsConfig',
  url: '/api/configurations/approval-user-configurations',
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'approvalUserConfigurationsList',
  url: '/api/configurations/approval-user-configurations'
});
 
export const actions = { approvalUserConfigurations, list };
export const epics = [ ...approvalUserConfifurationsEpics, ...listEpics ];
export default combineReducers({ $approvalUserConfigurations, $list });
