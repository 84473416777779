import React from 'react';
import { Link } from 'react-router-dom';

import Surface from '../surface';
import { button, container, text, outline, contained, pushTop, pushRight, pushBottom, pushLeft, link } from './Button.css';
import {withMediaQueryProps} from '../media-query-2';

const buttonPatterns = { text, outline, contained, link };
const pushOptions = {
  top: pushTop,
  right: pushRight,
  bottom: pushBottom,
  left: pushLeft,
}

function Button({ children, buttonPattern, push, style = {}, className = '', containerClassName = '', type = 'button', onClick, linkTo, mediaQuery={}, linkProps, ...rest }) {
  ({ [buttonPattern]: buttonPattern = contained } = buttonPatterns);
  ({ [push]: push = '' } = pushOptions);
  const buttonComponent = (
    <button {...{ style, className: `${button} ${buttonPattern} ${className}`, type, onClick, ...rest }}>
      {children}
    </button>
  );
  return (
    <Surface className={`${push} ${container} ${containerClassName}`} {...((buttonPattern == contained || buttonPattern == outline) ? { elevation: 1, hoverElevation: 1 } : { elevation: 0, hoverElevation: 0 })}>
      {linkTo && <Link to={linkTo} {...(linkProps) ? linkProps: {}}>{buttonComponent}</Link>}
      {!linkTo && buttonComponent}
    </Surface>
  )
}

export default withMediaQueryProps(Button);
