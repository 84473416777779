import { combineReducers } from 'redux';
import dux from './lib/dux';

const defaultComponents = {
  root: {
    type: 'Fragment',
  },
  highlightedComponent: null,
};

const setComponents = ({ components = defaultComponents, isFetching = false }) => ({ components, isFetching });
const cloneComponent = ({id}, {components={}}) => {
  const cloneComponent = (id, newParent) => {
    const {[id]: existingComponent} = components;
    const newComponentId = Math.random();
    const children = (existingComponent.children || []).map(child => cloneComponent(child, newComponentId));
    const newComponent = {
      ...existingComponent,
      parent: newParent,
      children: children.map(({newComponentId}) => newComponentId),
      isCollapsed: true,
    }
    return {
      newComponentId,
      newComponents: children.reduce((final, {newComponents={}}) => ({
        ...final,
        ...newComponents,
      }), {[newComponentId]: newComponent}),
    };
  }
  const {[id]: {parent: parentId, ...component}={}, [parentId]: {children=[], ...parent}={}} = components;
  if (!components[id] || !components[parentId])
    return empty();
  const {newComponentId, newComponents} = cloneComponent(id, parentId);
  const pos = children.indexOf(id) + 1;
  return {
    components: {
      ...components,
      ...newComponents,
      [id]: {...component, parent: parentId, isCollapsed: true,},
      [parentId]: {
        ...parent,
        isCollapsed: false,
        children: [...children.slice(0, pos), newComponentId, ...children.slice(pos)],
      },
    }
  };
}
const createComponent = ({id=Math.random(), component={}, beforeComponent, afterComponent}, {components={}}) => {
  const {parent: parentId='root'} = component;
  const {[parentId]: {children=[], ...parent}={}} = components;
  const pos = (beforeComponent ? children.indexOf(beforeComponent) : (afterComponent ? children.indexOf(afterComponent) + 1 : children.length));
  if (!parent)
    return empty();
  return {
    components: {
      ...components,
      [id]: {...component, parent: parentId},
      [parentId]: {
        ...parent,
        isCollapsed: false,
        children: [...children.slice(0, pos), id, ...children.slice(pos)],
      },
    }
  }
}
const updateComponent = ({id, component={}}, {components: {[id]: oldComponent={}, ...components}={}}) => ({components: {...components, [id]: {...oldComponent, ...component}}});
const deleteComponent = ({ id }, {components, components: {[id]: {parent: parentId}={}, [parentId]: {children=[], ...parent}={}}={}}) => ({
  components: {
    ...components,
    [id]: undefined,
    [parentId]: {
      ...parent,
      children: children.filter(child => child!=id),
    },
  }
});
const moveComponent = ({id, parent='root', beforeComponent, afterComponent}, {components={}}) => {
  const checkIfParentIsChild = (id, parent) => {
    if (parent=='root')
      return false;
    if (!parent || parent==id)
      return true;
    return checkIfParentIsChild(id, (components[parent] || {}).parent);
  }
  if (checkIfParentIsChild(id, parent))
    return {};

  const {[id]: {parent: oldParentId, ...component}={}, [oldParentId]: oldParent={},} = components;
  components = {
    ...components,
    [id]: {...component, parent},
    [oldParentId]: {
      ...oldParent,
      children: (oldParent.children || []).filter(child => child!=id),
    },
  };

  const {[parent]: {children=[], ...newParent}={}} = components;
  const pos = (beforeComponent ? children.indexOf(beforeComponent) : (afterComponent ? children.indexOf(afterComponent) + 1 : children.length));
  components = {
    ...components,
    [parent]: {
      ...newParent,
      isCollapsed: false,
      children: [...children.slice(0, pos), id, ...children.slice(pos)],
    },
  };

  return {components};
}
const toggleHighlightedComponent = ({id}, {components: {highlightedComponent, ...components}={}}) => ({components: {...components, highlightedComponent: (highlightedComponent==id) ? null : id}});
const updateTitle = ({ title }, { components = {} }) => ({ components: { ...components, title } });
const updateCompanyDetails = ({ companyDetails }, { components = {} }) => ({ components: { ...components, companyDetails } });
const updateCompanyLogo = ({ companyLogo }, { components = {} }) => ({ components: { ...components, companyLogo } });
const updateValue = ({ value }, { components = {} }) => ({ components: { ...components, value } });
const updateEntityField = (field = {}, { components: { entityField = {}, ...components } = {} }) => ({ components: { ...components, entityField: { ...entityField, ...field} } });

const actions = [
  { name: 'setComponents', getPayload: (components, isFetching=false) => ({isFetching, components,}),},
  {name: 'createComponent', getPayload: (component={}, {beforeComponent, afterComponent, id}={}) => ({id, component, beforeComponent, afterComponent,}),},
  {name: 'updateComponent', getPayload: (id, component) => ({id, component,}),},
  {name: 'deleteComponent', getPayload: (id) => ({id,}),},
  {name: 'moveComponent', getPayload: (id, parent, {beforeComponent, afterComponent}={}) => ({id, parent, beforeComponent, afterComponent,}),},
  {name: 'cloneComponent', getPayload: (id) => ({id}),},
  {name: 'toggleHighlightedComponent', getPayload: (id) => ({id}),},
  { name: 'updateTitle', getPayload: (title) => ({ title }), },
  { name: 'updateCompanyDetails', getPayload: (companyDetails) => ({ companyDetails }), },
  { name: 'updateCompanyLogo', getPayload: (companyLogo) => ({ companyLogo }), },
  { name: 'updateValue', getPayload: (value) => ({ value }), },
  { name: 'updateEntityField', getPayload: (name, field) => ({ [name]: field }), },
]

const actionReducers = { setComponents, cloneComponent, createComponent, updateComponent, deleteComponent, moveComponent, toggleHighlightedComponent, updateTitle, updateCompanyDetails, updateCompanyLogo, updateValue, updateEntityField};

const initialState = {
  components: defaultComponents,
  isFetching: true,
  error: null,
}

const {reducer: $components, actions: duxActions, epics} = dux({name: 'documentTemplate', initialState, actions, actionReducers});

export {duxActions as actions, epics};
export default combineReducers({$components});
