const routes = [
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    customProps: {},
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    hideHeader: true,
    customProps: {},
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/forgot-password',
    app: 'changePassword',
    hideHeader: true,
    customProps: {},
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/change-password',
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    equals: '/',
    app: 'shipmentPlanner',
    name: 'schedulesICICI',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
];

export default routes;