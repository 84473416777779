// extracted by mini-css-extract-plugin
export var h1 = "h1___jygle";
export var h2 = "h2___AhuWy";
export var h3 = "h3___fZJUF";
export var h4 = "h4___X9gZo";
export var h5 = "h5___cFlZf";
export var h6 = "h6___YoxxA";
export var small = "small___bJ602";
export var subtitle = "subtitle___eUzh_";
export var subtitleSmall = "subtitleSmall___GpO9I";
export var caption = "caption___uaHkk";
export var overline = "overline___dnIKN";
export var muted = "muted___LuqcA";
export var body = "body___f5wUT";
export var heading = "heading___aUBps";
export var smallThin = "smallThin___CIdTi";
export var sidenav = "sidenav___l8jXX";
export var sidenavSubItem = "sidenavSubItem___Mt8l8";
export var subHeading = "subHeading___mGdLP";