import { ofType } from 'redux-observable';
import { first, map, withLatestFrom, } from 'rxjs/operators';

const defaultSideNavView = {
  size: 'collapsed',
  currentTheme: 'white',
}

export const actions = {
  setSideNav: 'setting.setSideNav',
  getSideNavView: 'setting.getSideNavView',
  setSideNavView: 'setting.setSideNavView',
}
export default function reducer(state = {
  slideSideNav: false,
  sideNavView: undefined,
}, { type, payload = {} }) {
  switch (type) {
    case actions.setSideNav:
      return {
        ...state,
        ...payload
      }
    case actions.setSideNavView:
      return {
        ...state,
        ...payload
      }
    case actions.getSideNavView:
      return {
        ...state,
        ...payload,
      }
    default:
      return state;
  }
}
export const setSideNav = (value) => ({
  type: actions.setSideNav,
  payload: {
    slideSideNav: value,
  }
});
export const setSideNavView = (sideNavView) => ({
  type: actions.setSideNavView,
  payload: { sideNavView },
});
export const getSideNavView = (sideNavView = JSON.parse(localStorage.getItem('settings.sideNavView'))) => ({
  type: actions.getSideNavView,
  payload: {
    ...(sideNavView != null) ? { sideNavView } : {},
  },
});

export const setSideNavViewEpic = (action$, state$) => action$.pipe(
  ofType(actions.setSideNavView),
  withLatestFrom(state$),
  map(([{ payload: { sideNavView } = {} } = {},]) => {
    localStorage.setItem('settings.sideNavView', JSON.stringify(sideNavView));
    return getSideNavView();
  }),
);

export const getSideNavViewEpic = (action$, state$) => action$.pipe(
  ofType(actions.getSideNavView),
  first(),
  withLatestFrom(state$),
  map(([{ payload: { sideNavView } }]) => {
    if (sideNavView)
      return getSideNavView(sideNavView);
    const storedSideNavView = JSON.parse(localStorage.getItem('settings.sideNavView'));
    if (!storedSideNavView)
      localStorage.setItem('settings.sideNavView', JSON.stringify(defaultSideNavView));
    return getSideNavView();
  })
);