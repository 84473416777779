import { combineReducers } from 'redux';
import objectToQueryString from '../utils/objectToQueryString';
import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';
import createDux from './lib/createDux';

const { reducer: $shipmentOrders, actions: shipmentOrders, epics: shipmentOrdersEpics } = culdDux({
  name: 'shipmentOrders',
  url: '/api/shipments/v2/orders',
});

const { reducer: $orders, actions: orders, epics: ordersEpics } = culdDux({
  name: 'orders',
  url: '/api/orders/orders',
});

const { reducer: $lineItems, actions: lineItems, epics: lineItemsEpics } = culdDux({
  name: 'lineItems',
  url: '/api/orders/lineItems',
});

const { reducer: $bulkOrders, actions: bulkOrders, epics: bulkOrdersEpics } = culdDux({
  name: 'bulkOrders',
  url: '/api/orders/bulkOrders',
});

const { reducer: $bulkOrderFileUpload, actions: bulkOrderFileUpload, epics: bulkOrderFileUploadEpics } = culdDux({
  name: 'bulkOrderFileUpload',
  url: '/api/orders/bulkOrderFileUpload',
});

const { reducer: $uploadBulkOrderFile, actions: uploadBulkOrderFile, epics: uploadBulkOrderFileEpics } = createDux({
  name: 'bulkOrderFileUpload.upload',
  url: '/api/orders/bulkOrderFileUpload',
  headers: { 'Content-Type': 'FormData'},
});

const { reducer: $bulkOrderLogs, actions: bulkOrderLogs, epics: bulkOrderLogsEpics } = culdDux({
  name: 'bulkOrderLogs',
  url: '/api/orders/bulkOrderLogs',
});

const { reducer: $updateOrderMilestone, actions: updateOrderMilestone, epics: updateOrderMilestoneEpics } = updateDux({
  name: 'orderMilestone',
  url: '/api/orders/orders',
  getUrl: (url, { 0: { payload: { order } = {} } = {} }) => `${url}/${order}/updateMilestone/`,
  updatePayload: (order, data, options) => ({ order, data, options, isUpdating: true, }),
});

const { reducer: $updateLineItemMilestone, actions: updateLineItemMilestone, epics: updateLineItemMilestoneEpics } = updateDux({
  name: 'lineItemMilestone',
  url: '/api/orders/orders',
  getUrl: (url, { 0: { payload: { order, lineItem } = {} } = {} }) => `${url}/${order}/updateMilestone/${lineItem}/`,
  updatePayload: ({ order, lineItem }, data, options) => ({ order, lineItem, data, options, isUpdating: true, }),
});

const { reducer: $createLineItemShipment, actions: createLineItemShipment, epics: createLineItemShipmentEpics } = updateDux({
  name: 'lineItemShipment',
  url: '/api/orders/orders',
  getUrl: (url, { 0: { payload: { order, lineItem } = {} } = {} }) => `${url}/${order}/createShipment/${lineItem}/`,
  updatePayload: ({ order, lineItem }, data, options) => ({ order, lineItem, data, options, isUpdating: true, }),
});


const { reducer: $summary, actions: summary, epics: summaryEpics } = dux({
  name: 'orders.$summary',
  initialState: { isFetching: false, },
  actions: [
    { name: 'get', getPayload: (query) =>({query, isFetching: true })},
    { name: 'set', getPayload: ({ error, errorMessage = '', statuses, ...rest }) => ({ error, errorMessage, summary: statuses, ...rest, isFetching: false }) },
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: { url: '/api/orders/summary', method: 'GET' },
    getUrl: (url, {0: {payload: {query}={}}={}}=[]) => `${url}?${objectToQueryString(query)}`,
  }),
  ],
});

export const actions = { shipmentOrders, orders, lineItems, bulkOrders, bulkOrderFileUpload, uploadBulkOrderFile, bulkOrderLogs, updateOrderMilestone, updateLineItemMilestone, createLineItemShipment, summary,};
export const epics = [...shipmentOrdersEpics, ...ordersEpics, ...lineItemsEpics, ...bulkOrdersEpics, ...bulkOrderFileUploadEpics, ...uploadBulkOrderFileEpics, ...bulkOrderLogsEpics, ...updateOrderMilestoneEpics, ...updateLineItemMilestoneEpics, ...createLineItemShipmentEpics, ...summaryEpics,];
export default combineReducers({ $shipmentOrders, $orders, $lineItems, $bulkOrders, $bulkOrderFileUpload, $uploadBulkOrderFile, $bulkOrderLogs, $updateOrderMilestone, $updateLineItemMilestone, $createLineItemShipment, $summary,});
