import { combineReducers } from 'redux';
import createDux from './lib/createDux';

const {reducer: $create, actions: create, epics: createEpics} = createDux({
  name: 'hdfcLead',
  url: '/api/webforms/hdfc/',
});

export const actions = { create, };
export const epics = [...createEpics, ];
export default combineReducers({ $create, });