import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';

const { reducer: $companies, actions: companies, epics: companiesEpics } = culdDux({
  name: 'network.companies',
  url: '/api/auth/v2/network/companies/',
});
const { reducer: $roles, actions: roles, epics: rolesEpics } = culdDux({
  name: 'network.roles',
  url: '/api/auth/v2/network/roles/',
});

export const actions = { companies, roles };
export const epics = [...companiesEpics, ...rolesEpics];
export default combineReducers({ $companies, $roles });