import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';
import dux from './lib/dux';
import {ajaxEpic} from './lib/epic';

const { reducer: $createWithQuote, actions: createWithQuote, epics: createWithQuoteEpics } = createDux({
  name: 'enquiries',
  url: '/api/enquiries/v2/withQuote'
});

const { reducer: $createWithQuoteAndRates, actions: createWithQuoteAndRates, epics: createWithQuoteAndRatesEpics } = createDux({
  name: 'enquiriesWithRate',
  url: '/api/enquiries/v2/withQuoteAndSelectedRates'
});

const { reducer: $enquiryDetails, actions: enquiryDetails, epics: enquiryEpics } = detailsDux({
  name: 'enquiries',
  url: '/api/enquiries/v2',
  setDetailsFromListPayload: () => ({})
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'enquiries',
  url: '/api/enquiries/v2',
});

const { reducer: $addComment, actions: addComment, epics: addCommentEpics } = updateDux({
  name: 'enquiryComments',
  url: '/api/enquiries/v2/comment'
});

const { reducer: $getComments, actions: getComments, epics: getCommentsEpics } = listDux({
  name: 'getEnquiryComments',
  url: '/api/enquiries/v2/comments'
});

const { reducer: $orderConfirm, actions: orderConfirm, epics: orderConfirmEpics } = updateDux({
  name: 'orderConfirm',
  url: '/api/enquiries/v2/orderConfirm'
});

const { reducer: $getTNC, actions: getTNC, epics: getTNCEpics } = listDux({
  name: 'termAndCondition',
  url: '/api/enquiries/v2/termAndConditions'
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'EnquiryList',
  url: '/api/enquiries/v2/'
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'enquiries.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/enquiries/v2/summary', method: 'GET'},}),
  ],
});

export const actions = { createWithQuote, createWithQuoteAndRates, enquiryDetails, update, addComment, getTNC, list, summary, orderConfirm, getComments};
export const epics = [...createWithQuoteEpics, ...createWithQuoteAndRatesEpics, ...enquiryEpics, ...updateEpics, ...addCommentEpics, ...getTNCEpics, ...listEpics, ...summaryEpics, ...orderConfirmEpics, ...getCommentsEpics];
export default combineReducers({ $createWithQuote,  $createWithQuoteAndRates, $enquiryDetails, $update, $addComment, $getTNC, $list, $summary, $orderConfirm, $getComments });