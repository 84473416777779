import { combineReducers } from 'redux';
import updateDux from './lib/updateDux';


const {reducer: $updateTDS, actions: updateTDS, epics: updateTDSEpics} = updateDux({
  name: 'quickbooks.updateTDS',
  url: '/api/quickbooks/invoice/updateTds/',
});

export const actions = {updateTDS};
export const epics = [...updateTDSEpics];
export default combineReducers({$updateTDS});