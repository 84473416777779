
import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';

const { reducer: $orderRFQs, actions: orderRFQs, epics: orderRFQEpics } = culdDux({
  name: 'orderRFQs',
  url: '/api/order-rfq/rfqs',
});

const { reducer: $orderRFQLineItems, actions: orderRFQLineItems, epics: orderRFQLineItemsEpics } = culdDux({
  name: 'orderRFQLineItems',
  url: '/api/order-rfq/line-items',
});

const { reducer: $orderRFQSupplierQuotes, actions: orderRFQSupplierQuotes, epics: orderRFQSupplierQuoteEpics } = culdDux({
  name: 'orderRFQSupplierQuotes',
  url: '/api/order-rfq/supplier-quotes',
});

const { reducer: $orderRFQSupplierQuoteLineItems, actions: orderRFQSupplierQuoteLineItems, epics: orderRFQSupplierQuoteLineItemEpics } = culdDux({
  name: 'orderRFQSupplierQuoteLineItems',
  url: '/api/order-rfq/supplier-quote-line-items',
});

const { reducer: $createOrderRFQFromRequisition, actions: createOrderRFQFromRequisition, epics: createOrderRFQFromRequisitionEpics } = createDux({
  name: 'createOrderRFQFromRequisition',
  url: '/api/order-rfq/rfqs/create-from-requisition',
});

const { reducer: $createOrderRFQWithLineItems, actions: createOrderRFQWithLineItems, epics: createOrderRFQWithLineItemsEpics } = createDux({
  name: 'createOrderRFQWithLineItems',
  url: '/api/order-rfq/rfqs/create-order-rfq-with-line-items',
});

const { reducer: $deleteOrderRFQLineItem, actions: deleteOrderRFQLineItem, epics: deleteOrderRFQLineItemEpics } = updateDux({
  name: 'deleteOrderRFQLineItem',
  url: '/api/order-rfq/line-items/delete',
});

const { reducer: $awardSupplierQuote, actions: awardSupplierQuote, epics: awardSupplierQuoteEpics } = updateDux({
  name: 'awardSupplierQuote',
  url: '/api/order-rfq/supplier-quotes/award',
});



export const actions = { orderRFQs, orderRFQLineItems, orderRFQSupplierQuotes, orderRFQSupplierQuoteLineItems, createOrderRFQFromRequisition, deleteOrderRFQLineItem, createOrderRFQWithLineItems, awardSupplierQuote};
export const epics = [...orderRFQEpics, ...orderRFQLineItemsEpics, ...orderRFQSupplierQuoteEpics, ...orderRFQSupplierQuoteLineItemEpics, ...createOrderRFQFromRequisitionEpics, ...deleteOrderRFQLineItemEpics, ...createOrderRFQWithLineItemsEpics, ...awardSupplierQuoteEpics];
export default combineReducers({ $orderRFQs, $orderRFQLineItems, $orderRFQSupplierQuotes, $orderRFQSupplierQuoteLineItems, $createOrderRFQFromRequisition, $deleteOrderRFQLineItem, $createOrderRFQWithLineItems, $awardSupplierQuote });
