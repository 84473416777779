import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';

const { reducer: $createQuote, actions: createQuote, epics: createQuoteEpics } = createDux({
  name: 'enquiryQuotes',
  url: '/api/enquiries/v2/quote'
});

const { reducer: $updateQuoteRates, actions: updateQuoteRates, epics: updateQuoteRatesEpics } = updateDux({
  name: 'enquiryQuotes',
  url: '/api/enquiries/v2/quote/associated-rates'
});

const { reducer: $updateQuote, actions: updateQuote, epics: updateQuoteEpics } = updateDux({
  name: 'quotesupdate',
  url: '/api/enquiries/v2/quote'
});

const { reducer: $deleteQuoteRates, actions: deleteQuoteRates, epics: deleteQuoteRatesEpics } = updateDux({
  name: 'deleteQuotes',
  url: '/api/enquiries/v2/quote/remove-associated-rates'
});

const { reducer: $publish, actions: publish, epics: publishEpics } = updateDux({
  name: 'quotePublish',
  url: '/api/enquiries/v2/publishQuotes',
});

const { reducer: $getQuote, actions: getQuote, epics: getQuoteEpics } = detailsDux({
  name: 'getQuote',
  url: '/api/enquiries/v2/quote',
});

const { reducer: $getCustomerQuote, actions: getCustomerQuote, epics: getCustomerQuoteEpics } = detailsDux({
  name: 'getCustomerQuote',
  url: '/api/enquiries/v2/customer-quote',
});

export const actions = { createQuote, updateQuoteRates, deleteQuoteRates, updateQuote, publish, getQuote, getCustomerQuote };
export const epics = [...createQuoteEpics, ...updateQuoteRatesEpics, ...deleteQuoteRatesEpics, ...updateQuoteEpics, ...publishEpics, ...getQuoteEpics, ...getCustomerQuoteEpics];
export default combineReducers({ $createQuote, $updateQuoteRates, $deleteQuoteRates, $updateQuote, $publish, $getQuote, $getCustomerQuote });