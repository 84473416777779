import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import listDux from './lib/listDux';

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'ldb',
  url: '/api/ldb/equipments/',
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'ldb',
  url: '/api/ldb/equipments/',
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'ldb',
  url: '/api/ldb/equipments/'
});

const { reducer: $fetch, actions: fetch, epics: fetchEpics } = listDux({
  name: 'fetch',
  url: '/api/ldb/fetch'
});

export const actions = { details, create, list, fetch };
export const epics = [ ...detailsEpics, ...createEpics, ...listEpics, ...fetchEpics ];
export default combineReducers({ $details, $create, $list, $fetch});