import { combineReducers } from 'redux';
import objectToQueryString from '../utils/objectToQueryString';

import detailsDux from './lib/detailsDux';
import listDux from './lib/listDux';;

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'journeys',
  url: '/api/journeys/journeys/shipment/',
});

const { reducer: $shipmentEquipmentsJourneyList, actions: shipmentEquipmentsJourneyList, epics: shipmentEquipmentsJourneyListEpics } = listDux({
  name: 'shipmentEquipmentsJourneyList',
  url: '/api/journeys/equipments/shipment',
  getUrl: (url, {0: {payload: {query:id}={}}={}}=[]) => `${url}/${id}`, 
});

const { reducer: $shipmentTransportJourneyList, actions: shipmentTransportJourneyList, epics: shipmentTransportJourneyListEpics } = listDux({
  name: 'shipmentTransportJourneyList',
  url: '/api/journeys/transport/shipment',
  getUrl: (url, {0: {payload: {query:id}={}}={}}=[]) => `${url}/${id}`, 
});

const { reducer: $shipmentTerminalTrackingList, actions: shipmentTerminalTrackingList, epics: shipmentTerminalTrackingListEpics } = listDux({
  name: 'shipmentTerminalTrackingList',
  url: '/api/journeys/terminal-tracking/shipment',
  getUrl: (url, {0: {payload: {query:id}={}}={}}=[]) => `${url}/${id}`, 
});


// const {reducer: $details, actions: details, epics: detailsEpics} = detailsDux({
//   name: 'journeys',
//   setFromListPayload: ({_id, record}={}) => ({_id, record,  journeys: undefined, isFetching: false}),
//   actions: [
//     {name: 'getJourney', getPayload: (_id, journeysQuery) => ({_id, journeysQuery, isFetchingJourneys: true})},
//     {name: 'setJourney', getPayload: (payload={}) => {
//       console.log(payload);
//       return ({...payload, isFetchingJourneys: false});
//     }},
//   ],
//   epics: [
//     ({getActionType, getAction}) => ajaxEpic({
//       inActionType: getActionType('getJourney'), 
//       outAction: getAction('setJourney'), 
//       request: {
//         url: '/api/journeys/journeys/',
//       }, 
//       getUrl: (url, {0: {payload: {_id, journeysQuery}={}}={}}=[]) => `${url}/${_id}/?${objectToQueryString(journeysQuery)}`,
//       switchRequests: false,
//       withLatestState: true,
//       processResponse: ([{response: {data: {record=[],}={}, error, success}={}}={}, {0: {payload: {_id}={}}={}}=[]]=[]) =>  ({
//         ...(!success ? {_id, error: 'Server Error', errorMessage: 'Server Error'} : {}),
//         ...(error ? {_id, error, errorMessage: error.message || error.toString() || 'Server Error'} : {}),
//         ...((record) ? {_id, journey:record} : {}),
//       }),
//     }),
//   ],
// });

export const actions = {details, shipmentEquipmentsJourneyList, shipmentTransportJourneyList, shipmentTerminalTrackingList};
export const epics = [...detailsEpics, ...shipmentEquipmentsJourneyListEpics, ...shipmentTransportJourneyListEpics, ...shipmentTerminalTrackingListEpics];
export default combineReducers({$details, $shipmentEquipmentsJourneyList, $shipmentTransportJourneyList, $shipmentTerminalTrackingList});