import { ajaxEpic } from './epic';
import dux from './dux';

const updateDux = ({
  name,
  storeName = `${name}.$update`,
  initialState = { isUpdating: false, data: undefined, record: undefined, error: undefined, errorMessage: '' },
  actions = [],
  epics = [],
  idField = '_id',
  updatedRecordField,
  updatePayload = (_id, data, options) => ({ [idField]: _id, data, options, isUpdating: true, }),
  setUpdatePayload = ({ error = undefined, errorMessage = '', [idField]: _id, [updatedRecordField || 'record']: record, ...payload } = {}) => ({
    ...payload,
    isUpdating: false,
    error,
    errorMessage,
    record,
    ...((!error && !errorMessage && !updatedRecordField && !record && !!_id) ? { record: { [idField]: _id, ...payload } } : {}),
    ...(!!updatedRecordField ? { [updatedRecordField]: record } : {}),
  }),
  withLatestState = true,
  url,
  method = 'POST',
  headers = {},
  request = { url, method, headers },
  getUrl = (url, { 0: { payload: { [idField]: _id } = {} } = {} }) => `${url}/${_id}/`,
  switchRequests,
  getRequest,
  processResponse,
  processError,
  getOutAction,
  ...props
}) => dux({
  name: storeName,
  initialState,
  actions: [
    { name: 'update', getPayload: updatePayload },
    { name: 'set', getPayload: setUpdatePayload },
    ...actions,
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('update'), outAction: getAction('set'), withLatestState, request, getUrl, switchRequests, getRequest, processResponse, processError, getOutAction, }),
    ...epics,
  ],
  ...props,
});

export default updateDux;