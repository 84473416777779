import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import auth, { getUserTokenEpic, setAuthTokenEpic, getUsersEpic, authenticateEpic, getEnterprisePlanEpic, getUserCompanyEpic, signUpEpic, loginEpic, logoutEpic, logoutFromOtherTabEpic, forgotPasswordEpic, generateResetPasswordTokenEpic, getResetPasswordTokenEpic, updatePasswordEpic } from './auth';
import ports, { epics as portEpics, actions as portActions } from './ports';
import googlePlaces, { epics as googlePlaceEpics, actions as googlePlaceActions } from './googlePlaces';
import exchangeRates, { epics as exchangeRateEpics, actions as exchangeRateActions } from './exchangeRates';
import containerTypes, { epics as containerTypeEpics, actions as containerTypeActions } from './containerTypes';
import schedules, { epics as scheduleEpics, actions as scheduleActions } from './schedules';
import enquiriesV2, { epics as enquiryV2Epics, actions as enquiryV2Actions } from './enquiriesV2';
import quotes, { epics as quotesEpics, actions as quotesActions } from './quotes';
import feedback, { epics as feedbackEpics, actions as feedbackActions } from './feedback';
import rfqs, { epics as rfqEpics, actions as rfqActions } from './rfqs';
import rfqQuotes, { epics as rfqQuoteEpics, actions as rfqQuoteActions } from './rfqQuotes';
import rfqTerms, { epics as rfqTermEpics, actions as rfqTermActions } from './rfqTerms';
import rfqTermGroups, { epics as rfqTermGroupEpics, actions as rfqTermGroupActions } from './rfqTermGroups';
import rfqVendorGroups, { epics as rfqVendorGroupEpics, actions as rfqVendorGroupActions } from './rfqVendorGroups';
import rfqNegotiations, { epics as rfqNegotiationEpics, actions as rfqNegotiationActions } from './rfqNegotiations';
import companies, { epics as companyEpics, actions as companyActions } from './companies';
import users, { epics as userEpics, actions as userActions } from './users';
import ratesV3, { epics as ratesV3Epics, actions as ratesV3Actions } from './ratesV3';
import shipper, { epics as shipperEpics, actions as shipperAction } from './shipper';
import enterprises, { epics as enterpriseEpics, actions as enterpriseActions } from './enterprise';
import documentsV2, { epics as documentEpics, actions as documentActions } from './documentsV2';
import shipments, { epics as shipmentsEpics, actions as shipmentsActions } from './shipments';
import emission, { epics as emissionEpics, actions as emissionActions } from './emission';
import invoices, { epics as invoicesEpics, actions as invoicesActions } from './invoices';
import searchDialog, { getSearchHistoryEpic, setSearchHistoryEpic } from './searchDialog';
import iciciLead, { epics as iciciLeadEpics, actions as iciciLeadActions } from './iciciLead';
import hdfcLead, { epics as hdfcLeadEpics, actions as hdfcLeadActions } from './hdfcLead';
import pay2corp, { epics as pay2corpEpics, actions as pay2corpActions } from './pay2corp';
import quickbooks, { epics as quickbooksEpics, actions as quickbooksActions } from './quickbooks';
import zohobooks, { epics as zohobooksEpics, actions as zohobooksActions } from './zohobooks';
import setting, { setSideNavViewEpic, getSideNavViewEpic } from './setting';
import tracking, { epics as trackingEpics, actions as trackingActions } from './tracking';
import report, { epics as reportEpics, actions as reportActions } from './report';
import compose, { epics as composeEpics, actions as composeActions } from './compose';
import composeDocumentTemplate, { epics as composeDocumentTemplateEpics, actions as composeDocumentTemplateActions } from './composeDocumentTemplate';
import documentTemplate, { epics as documentTemplateEpics, actions as documentTemplateActions } from './documentTemplate';
import notificationPreferences, { epics as notificationPreferencesEpics, actions as notificationPreferencesActions } from './notificationPreferences';
import regularization, { epics as regularizationEpics, actions as regularizationActions } from './regularization';
import pdfReader, { epics as pdfReaderEpics, actions as pdfReaderActions } from './pdfReader';
import demoRequests, { epics as demoRequestsEpics, actions as demoRequestsActions } from './demoRequests';
import journeys, { epics as journeyEpics, actions as journeyActions } from './journeys';
import ldb, { epics as ldbEpics, actions as ldbActions } from './ldb';
import positions, { epics as positionEpics, actions as positionActions } from './positions';
import events, { epics as eventEpics, actions as eventActions } from './events';
import bookings, { epics as bookingEpics, actions as bookingActions } from './bookings';
import harmonizedSystemCodes, { epics as harmonizedSystemCodeEpics, actions as harmonizedSystemCodeActions } from './harmonizedSystemCodes';
import products, { epics as productEpics, actions as productActions } from './products';
import companyLocations, { epics as companyLocationEpics, actions as companyLocationActions } from './companyLocations';
import orders, { epics as orderEpics, actions as orderActions } from './orders';
import orderRFQs, { epics as orderRFQEpics, actions as orderRFQActions } from './orderRFQs';
import requisitions, { epics as requisitionEpics, actions as requisitionActions } from './requisitions';
import dashboard, { epics as dashboardEpics, actions as dashboardActions } from './dashboard';
import containerInventory, {epics as containerInventoryEpics,  actions as containerInventoryActions } from './containerInventory';
import manifests, { epics as manifestEpics, actions as manifestsActions } from './manifests';
import containerShipments, { epics as containerShipmentEpics, actions as containerShipmentActions } from './containerShipment';
import network, { epics as networkEpics, actions as networkActions } from './network';
import oceanio, { epics as oceanioEpics, actions as oceanioActions } from './oceanio';
import purchaseOrders, { epics as purchaseOrderEpics, actions as purchaseOrderActions } from './purchaseOrders';
import configurations, {epics as configurationsEpics, actions as configurationsActions } from './configurations';

export const actions = {
  ports: portActions,
  googlePlaces: googlePlaceActions,
  containerTypes: containerTypeActions,
  exchangeRates: exchangeRateActions,
  schedules: scheduleActions,
  enquiriesV2: enquiryV2Actions,
  quotes: quotesActions,
  feedback: feedbackActions,
  rfqs: rfqActions,
  rfqQuotes: rfqQuoteActions,
  rfqTerms: rfqTermActions,
  rfqTermGroups: rfqTermGroupActions,
  rfqVendorGroups: rfqVendorGroupActions,
  rfqNegotiations: rfqNegotiationActions,
  companies: companyActions,
  users: userActions,
  ratesV3: ratesV3Actions,
  shipper: shipperAction,
  enterprises: enterpriseActions,
  documentsV2: documentActions,
  shipments: shipmentsActions,
  emission: emissionActions,
  invoices: invoicesActions,
  iciciLead: iciciLeadActions,
  hdfcLead: hdfcLeadActions,
  pay2corp: pay2corpActions,
  quickbooks: quickbooksActions,
  zohobooks: zohobooksActions,
  report: reportActions,
  tracking: trackingActions,
  compose: composeActions,
  composeDocumentTemplate: composeDocumentTemplateActions,
  documentTemplate: documentTemplateActions,
  notificationPreferences: notificationPreferencesActions,
  regularization: regularizationActions,
  pdfReader: pdfReaderActions,
  demoRequests: demoRequestsActions,
  journeys: journeyActions,
  ldb: ldbActions,
  positions: positionActions,
  events: eventActions,
  bookings: bookingActions,
  harmonizedSystemCodes: harmonizedSystemCodeActions,
  products: productActions,
  companyLocations: companyLocationActions,
  orders: orderActions,
  orderRFQs: orderRFQActions,
  requisitions: requisitionActions,
  dashboard: dashboardActions,
  containerInventory: containerInventoryActions,
  manifests: manifestsActions,
  containerShipments: containerShipmentActions,
  network: networkActions,
  oceanio: oceanioActions,
  purchaseOrders: purchaseOrderActions,
  configurations: configurationsActions
}

export const epic = combineEpics(
  getUserTokenEpic,
  setAuthTokenEpic,
  getUsersEpic,
  authenticateEpic,
  getEnterprisePlanEpic,
  getUserCompanyEpic,
  signUpEpic,
  loginEpic,
  logoutEpic,
  logoutFromOtherTabEpic,
  forgotPasswordEpic,
  generateResetPasswordTokenEpic,
  getResetPasswordTokenEpic,
  updatePasswordEpic,
  getSearchHistoryEpic,
  setSearchHistoryEpic,
  setSideNavViewEpic,
  getSideNavViewEpic,
  ...portEpics,
  ...googlePlaceEpics,
  ...containerTypeEpics,
  ...exchangeRateEpics,
  ...scheduleEpics,
  ...enquiryV2Epics,
  ...quotesEpics,
  ...feedbackEpics,
  ...rfqEpics,
  ...rfqQuoteEpics,
  ...rfqTermEpics,
  ...rfqTermGroupEpics,
  ...rfqVendorGroupEpics,
  ...rfqNegotiationEpics,
  ...companyEpics,
  ...userEpics,
  ...ratesV3Epics,
  ...shipperEpics,
  ...enterpriseEpics,
  ...documentEpics,
  ...shipmentsEpics,
  ...emissionEpics,
  ...invoicesEpics,
  ...iciciLeadEpics,
  ...hdfcLeadEpics,
  ...pay2corpEpics,
  ...quickbooksEpics,
  ...zohobooksEpics,
  ...trackingEpics,
  ...reportEpics,
  ...composeEpics,
  ...composeDocumentTemplateEpics,
  ...documentTemplateEpics,
  ...notificationPreferencesEpics,
  ...regularizationEpics,
  ...pdfReaderEpics,
  ...demoRequestsEpics,
  ...journeyEpics,
  ...ldbEpics,
  ...positionEpics,
  ...eventEpics,
  ...bookingEpics,
  ...harmonizedSystemCodeEpics,
  ...productEpics,
  ...companyLocationEpics,
  ...orderEpics,
  ...orderRFQEpics,
  ...requisitionEpics,
  ...dashboardEpics,
  ...containerInventoryEpics,
  ...manifestEpics,
  ...containerShipmentEpics,
  ...networkEpics,
  ...oceanioEpics,
  ...purchaseOrderEpics,
  ...configurationsEpics
);

export default combineReducers({
  auth,
  ports,
  googlePlaces,
  containerTypes,
  exchangeRates,
  schedules,
  enquiriesV2,
  quotes,
  feedback,
  rfqs,
  rfqQuotes,
  rfqTerms,
  rfqTermGroups,
  rfqVendorGroups,
  rfqNegotiations,
  companies,
  users,
  ratesV3,
  shipper,
  enterprises,
  documentsV2,
  shipments,
  emission,
  invoices,
  searchDialog,
  iciciLead,
  hdfcLead,
  pay2corp,
  quickbooks,
  zohobooks,
  setting,
  report,
  tracking,
  compose,
  composeDocumentTemplate,
  documentTemplate,
  notificationPreferences,
  regularization,
  pdfReader,
  demoRequests,
  journeys,
  ldb,
  positions,
  events,
  bookings,
  harmonizedSystemCodes,
  products,
  companyLocations,
  orders,
  orderRFQs,
  requisitions,
  dashboard,
  containerInventory,
  manifests,
  containerShipments,
  network,
  oceanio,
  purchaseOrders,
  configurations
});
