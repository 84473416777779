import {combineReducers} from 'redux';
import objectToQueryString from '../utils/objectToQueryString';

import {ajaxEpic, getDetailsOnUpdateEpic} from './lib/epic';
import dux from './lib/dux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';

const {reducer: $list, actions: list, epics: listEpics} = listDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});
const {reducer: $pendingList, actions: pendingList, epics: pendingListEpics} = listDux({
  name: 'rfqsPendingQuotes',
  url: '/api/rfq/rfqs/pending-quotes',
});

const {reducer: $details, actions: details, epics: detailsEpics} = detailsDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});

const {reducer: $create, actions: create, epics: createEpics} = createDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});

const {reducer: $update, actions: update, epics: updateEpics} = updateDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
  updatedRecordField: 'rfq',
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'rfqs.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/rfq/rfqs/summary', method: 'GET'},}),
  ],
});

const { reducer: $history, actions: history, epics: historyEpics } = listDux({
  name: 'rfqs.$history',
  url: '/api/rfq/rfqs/',
  getUrl: (url, { 0: { payload: { query: { rfq, ...query } } = {} } = {} }) => `${url}${rfq}/quoteHistory?${objectToQueryString(query)}`,
});

const { reducer: $auditTrail, actions: auditTrail, epics: auditTrailEpics } = listDux({
  name: 'rfqs.$auditTrail',
  url: '/api/rfq/rfqs/',
  getUrl: (url, { 0: { payload: { query: { rfq, ...query } } = {} } = {} }) => `${url}${rfq}/auditTrail?${objectToQueryString(query)}`,
});

const {reducer: $deleteDocument, actions: deleteDocument, epics: deleteDocumentEpics} = updateDux({
  name: 'rfqs.delete',
  url: '/api/rfq/rfqs/deleteDocument'
});

const {reducer: $uploadDocument, actions: uploadDocument, epics: uploadDocumentEpics} = updateDux({
  name: 'rfqs.upload', 
  url: '/api/rfq/rfqs/upload',
  headers: { 'Content-Type': 'FormData'},
});

export const actions = { list, details, create, update, summary, history, auditTrail, deleteDocument, uploadDocument, pendingList };
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics, ...summaryEpics, ...historyEpics, ...auditTrailEpics, ...deleteDocumentEpics, ...uploadDocumentEpics, ...pendingListEpics];
export default combineReducers({ $list, $details, $create, $update, $summary, $history, $auditTrail, $deleteDocument, $uploadDocument, $pendingList });