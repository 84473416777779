import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';

const { reducer: $bookings, actions: bookings, epics: bookingEpics } = culdDux({
  name: 'bookings',
  url: '/api/bookings/',
});

const { reducer: $submitBooking, actions: submitBooking, epics: submitBookingEpic } = updateDux({
  name: 'bookings.submit',
  url: '/api/bookings/submit',
});

const { reducer: $cancelBooking, actions: cancelBooking, epics: cancelBookingEpic } = updateDux({
  name: 'bookings.cancel',
  url: '/api/bookings/cancel',
});

const { reducer: $amendBooking, actions: amendBooking, epics: amendBookingEpic } = updateDux({
  name: 'bookings.amend',
  url: '/api/bookings/amend',
});



export const actions = { ...bookings, submitBooking, cancelBooking, amendBooking };
export const epics = [...bookingEpics, ...submitBookingEpic, ...cancelBookingEpic, ...amendBookingEpic, ];
export default combineReducers({ $bookings, $submitBooking, $cancelBooking, $amendBooking });