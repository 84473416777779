import { combineReducers } from 'redux';
import updateDux from './lib/updateDux';


const {reducer: $updateTDS, actions: updateTDS, epics: updateTDSEpics} = updateDux({
  name: 'zohobooks.updateTDS',
  url: '/api/zohobooks/invoices/updateTds',
});

export const actions = {updateTDS};
export const epics = [...updateTDSEpics];
export default combineReducers({$updateTDS});