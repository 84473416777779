
import { combineReducers } from 'redux';

import createDux from './lib/createDux';
import updateDux from './lib/updateDux';


  const { reducer: $create, actions: create, epics: createEpics } = createDux({
    name: 'RequestDemo',
    url: '/api/users/request-demo',
    getRequest:(url, {headers, ...request}, [{payload: {data, options}}, {auth: {authToken}={}}={}]) => {
      console.log(data);
      return {
        ...request,
        url,
        headers: {
          ...(authToken ? {Authorization: `Bearer ${authToken}`} : {}),
        },
        method: 'POST',
        body: data,
      };
    },
 
  });

  
const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'RequestDemo',
  url: '/api/request-demo',
});
  
  export const actions = {create, update};
  export const epics = [...createEpics, ...updateEpics];
  export default combineReducers({ $create,$update});