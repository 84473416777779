import {combineReducers} from 'redux';
import objectToQueryString from '../utils/objectToQueryString';

import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import {ajaxEpic} from './lib/epic';

const {reducer: $list, actions: list, epics: listEpics} = listDux({
  name: 'schedules',
  url: '/api/schedules/v2/search',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200){
      if(response.success){
        return {
          ...response.data,
          isFetching: false,
          success: true,
          carriers : (response.data.filter||{}).comingFromRates ? response.data.filter.carrierDetails : response.data.carriers,
        };
      }
      else {
        return {...response}
      }
    }
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

const {reducer: $ratesList, actions: ratesList, epics: ratesListEpics} = listDux({
  name: 'lclRates',
  url: '/api/rates/v3/rateInventory/lclRates/',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200){
      if(response.success){
        return {
          data:response.data,
          isFetching: false,
          count:1,
        };
      }
      else {
        return {...response}
      }
    }
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

const {reducer: $fetchRateList, actions: fetchRateList, epics: fetchRateListEpics} = listDux({
  name: 'fetchRateList',
  url: '/api/rates/v3/rateInventory/estimates/',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200) {
      if(response.success){
        return {
          ...response.data,
          isFetching: false,
          success: true
        };
      }
      else {
        return {...response}
      }
    }
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

const {reducer: $fetchScheduleList, actions: fetchScheduleList, epics: fetchScheduleListEpics} = listDux({
  name: 'fetchScheduleList',
  url: '/api/schedules/v2/',
});

const { reducer: $rateDetails, actions: rateDetails, epics: rateDetailsEpics } = detailsDux({
  name: 'rateDetails',
  url: '/api/rates/v3/rateinventory',
  setDetailsFromListPayload: () => ({}),
});

const {reducer: $details, actions: details, epics: detailsEpics} = detailsDux({
  name: 'schedules',
  setFromListPayload: ({_id, record}={}) => ({_id, record, rates: undefined, isFetching: false}),
  actions: [
    {name: 'getRates', getPayload: (_id, ratesQuery) => ({_id, ratesQuery, isFetchingRates: true})},
    {name: 'setRates', getPayload: (payload={}) => ({...payload, isFetchingRates: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({
      inActionType: getActionType('getRates'),
      outAction: getAction('setRates'),
      request: {
        url: '/api/rates/v3/rateInventory/estimate/',
      },
      withLatestState: true,
      getUrl: (url, {0: {payload: {ratesQuery}={}}={}}=[]) => `${url}?${objectToQueryString(ratesQuery)}`,
      switchRequests: false,
      processResponse: ([{response: {data={}, error, success}={}}={}, {0: {payload: {_id}={}}={}}=[]]=[]) =>  ({
        ...(!success ? {_id, error: 'Server Error', errorMessage: 'Server Error'} : {}),
        ...(error ? {_id, error, errorMessage: error.message || error.toString() || 'Server Error'} : {}),
        ...(data ? {_id, rates: data} : {}),
      }),
    }),
  ],
});

const {reducer: $fetchScheduleByCarrier, actions: fetchScheduleByCarrier, epics: fetchScheduleByCarrierEpics} = detailsDux({
  name: 'fetchScheduleByCarrier',
  setFromListPayload: ({_id, record}={}) => ({_id, record,  schedules: undefined, isFetching: false}),
  actions: [
    {name: 'getScheduleByCarrier', getPayload: (_id, scheduleQuery) => ({_id, scheduleQuery, isFetchedSchedule: true})},
    {name: 'setScheduleByCarrier', getPayload: (payload={}) => ({...payload, isFetchedSchedule: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({
      inActionType: getActionType('getScheduleByCarrier'),
      outAction: getAction('setScheduleByCarrier'),
      request: {
        url: '/api/schedules/v2/',
      },
      getUrl: (url, {0: {payload: {scheduleQuery}={}}={}}=[]) => `${url}?${objectToQueryString(scheduleQuery)}`,
      switchRequests: false,
      processResponse: ([{response: {data={}, error, success}={}}={}, {0: {payload: {_id}={}}={}}=[]]=[]) =>  ({
        ...(!success ? {_id, error: 'Server Error', errorMessage: 'Server Error'} : {}),
        ...(error ? {_id, error, errorMessage: error.message || error.toString() || 'Server Error'} : {}),
        ...(data ? {_id, schedules: data} : {}),
      }),
    }),
  ],
});

export const actions = {list, ratesList, fetchScheduleList, fetchRateList, details, rateDetails, fetchScheduleByCarrier};
export const epics = [...listEpics, ...ratesListEpics, ...fetchScheduleListEpics, ...fetchRateListEpics, ...detailsEpics, ...rateDetailsEpics, ...fetchScheduleByCarrierEpics];
export default combineReducers({$list,  $ratesList, $fetchScheduleList, $fetchRateList, $details, $rateDetails, $fetchScheduleByCarrier,});
